import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  Pagination,
  Table,
  TableProps,
  PropertyFilter,
  Toggle,
  SpaceBetween,
  Popover,
  Link,
  Icon,
  CollectionPreferences,
} from '@amzn/awsui-components-react';
import { PropertyFilterProperty } from '@cloudscape-design/collection-hooks';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../store/challenge.context';
import { useSplitPanel } from '../../../store/split-panel.context';
import { ChallengeDescriptor, ChallengeListItem } from '../../../types/Challenge';
import { i18nKeys } from '../../../utils/i18n.utils';
import { TableEmptyState } from '../TableEmptyState';
import { TableHeader } from '../TableHeader';
import { COLUMN_DEFINITIONS, filteringFunction } from './browse-challenges-list-config';
import BrowseChallengesSplitPanel from './BrowseChallengesSplitPanel';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react/uxdg';
import { UserPreferenceKeys } from '@/src/store/user.context';
import { paginationLabels } from '@/src/utils/table.utils';
import RefreshButton from '@/src/components/common/RefreshButton';

interface BrowseChallengesOpenSearchProps {
  handleChallengeAction?: (payload: ChallengeDescriptor[]) => void;
  toggleChallengeInfo: (challengeListItem: ChallengeListItem) => void;
  showHideUnstableToggle?: boolean;
  actionButtons?: ReactNode;

  // These params are currently unused; They will come into use once this table and the BrowseChallenges table
  // have been unified. See https://sim.amazon.com/issues/JAM-11898
  currentChallengeDescriptors: ChallengeDescriptor[];
  showSelectedSplitPanel?: boolean;
  editMode?: boolean;
  displayChallenges?: string[];
  excludedChallenges?: ChallengeDescriptor[];
}

const BrowseChallengesOpenSearch: React.FC<BrowseChallengesOpenSearchProps> = ({
  handleChallengeAction,
  currentChallengeDescriptors,
  toggleChallengeInfo,
  editMode,
  displayChallenges = [],
  showSelectedSplitPanel = true,
  showHideUnstableToggle = false, // by default toggle will not be visible if you not passed from parents
  actionButtons,
  excludedChallenges = [],
}) => {
  const {
    challengeListOpenSearchItems,
    challengeListItems,
    loadingOpenSearchChallenges,
    getChallengeDescriptorsToCopy,
    challengeRequestOptions,
    setChallengeRequestOptions,
    challengesCount,
    getOpenSearchChallenges,
  } = useChallenges();
  const [filteredChallenges, setFilteredChallenges] = useState<ChallengeListItem[]>([]);
  const [selectedChallenges, setSelectedChallenges] = useState<ChallengeListItem[]>([]);
  const { renderSplitPanelContent, toggleShowSplitPanel } = useSplitPanel();
  const [toggleUnstableChallenges, setToggleUnstableChallenges] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    void getOpenSearchChallenges(false, true, editMode);
  }, [challengeRequestOptions]);
  const allActionButtons = showHideUnstableToggle ? (
    <SpaceBetween direction="horizontal" size="xs" alignItems="center">
      <Toggle
        disabled={loadingOpenSearchChallenges}
        checked={toggleUnstableChallenges}
        onChange={({ detail }) => setToggleUnstableChallenges(detail.checked)}
      >
        {t(i18nKeys.eventTemplates.table.header.actionToggleChallenges)}
      </Toggle>
      <Popover content={t(i18nKeys.eventTemplates.table.header.popoverContent)} triggerType="custom">
        <Link ariaLabel={t(i18nKeys.general.info)}>
          <Icon name="status-info" alt="info" />
        </Link>
      </Popover>
      <RefreshButton
        onClickHandler={() => void getOpenSearchChallenges(false, true, editMode)}
        disabled={loadingOpenSearchChallenges}
      />
      {actionButtons}
    </SpaceBetween>
  ) : (
    <SpaceBetween direction="horizontal" size="xs" alignItems="center">
      <RefreshButton
        onClickHandler={() => void getOpenSearchChallenges(false, true, editMode)}
        disabled={loadingOpenSearchChallenges}
      />
      {actionButtons}
    </SpaceBetween>
  );

  // toggleUnstableChallenges remains a server-side filtering option,
  // so toggling it should trigger another call of the API.
  useEffect(() => {
    setChallengeRequestOptions({
      ...challengeRequestOptions,
      stability: toggleUnstableChallenges,
    });
  }, [toggleUnstableChallenges]);

  useEffect(() => {
    if (!showSelectedSplitPanel) return;
    if (selectedChallenges.length === 0) {
      toggleShowSplitPanel(false);
      return;
    }

    toggleShowSplitPanel(true);
    renderSplitPanelContent(
      t(i18nKeys.challenges.browseChallenges.selectedChallenges, {
        selectedChallengeCount: selectedChallenges.length,
      }),
      <BrowseChallengesSplitPanel selectedChallenges={selectedChallenges || []} />
    );
  }, [selectedChallenges]);

  useEffect(() => {
    populateSelectedChallengesFromCurrentDescriptors();
  }, [currentChallengeDescriptors]);

  const lastUsedColumnIndex = COLUMN_DEFINITIONS(toggleChallengeInfo, editMode).findIndex(
    (columnDef: TableProps.ColumnDefinition<ChallengeListItem>) => columnDef.id === 'last-used'
  );

  useEffect(() => {
    if (challengeListOpenSearchItems) filterChallenges();
  }, [challengeListOpenSearchItems, editMode]);

  const filterChallenges = () => {
    let newFilteredChallenges: ChallengeListItem[] | undefined = challengeListOpenSearchItems;

    // This filtering is only performed if `displayChallenges` or `excludedChallenges` props have items.
    // For the /challenges page, these lists will always be empty, and thus this function filters nothing.
    // However, these *are* used when listing challenges for challenge sets. Currently, challenge set challenge tables
    // use the BrowseChallenges component, which also uses OpenSearch but with a different pagination implementation.
    // TODO - When unifying the two tables, ensure this front-end filtering logic stays consistent.
    //   https://sim.amazon.com/issues/JAM-11898
    if (displayChallenges.length > 0 && !editMode) {
      newFilteredChallenges = challengeListItems?.filter((challenge) =>
        displayChallenges.includes(challenge.challengeId || '')
      );
    }

    if (excludedChallenges.length > 0) {
      newFilteredChallenges = newFilteredChallenges?.filter((challenge) => {
        const foundChallengeIndex = excludedChallenges.findIndex(
          (excludedChallenge: ChallengeDescriptor) => excludedChallenge.challengeId === challenge.challengeId
        );
        return foundChallengeIndex < 0;
      });
    }

    if (newFilteredChallenges) setFilteredChallenges(newFilteredChallenges);
  };

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 10,
    visibleContent: ['title', 'type'],
  });

  const filteringProperties: PropertyFilterProperty[] = [
    {
      key: 'title',
      operators: ['=', ':'],
      propertyLabel: 'Title',
      groupValuesLabel: 'title',
    },
    {
      key: 'status',
      operators: ['=', ':'],
      propertyLabel: 'Status',
      groupValuesLabel: 'status',
    },
    {
      key: 'owner',
      operators: ['=', ':'],
      propertyLabel: 'Owner',
      groupValuesLabel: 'owner',
    },
    {
      key: 'category',
      operators: ['=', ':'],
      propertyLabel: 'Category',
      groupValuesLabel: 'category',
    },
    {
      key: 'difficulty',
      operators: ['='],
      propertyLabel: 'Difficulty',
      groupValuesLabel: 'difficulty',
    },
    {
      key: 'awsServices',
      operators: ['=', ':'],
      propertyLabel: 'AWS Service',
      groupValuesLabel: 'awsServices',
    },
    {
      key: 'tags',
      operators: ['=', ':'],
      propertyLabel: 'Tag',
      groupValuesLabel: 'tags',
    },
  ];

  const { items, actions, collectionProps, filteredItemsCount, paginationProps, propertyFilterProps } = useCollection(filteredChallenges, {
    filtering: {
      filteringFunction: filteringFunction(t),
      empty: (
        <TableEmptyState
          title={t(i18nKeys.challenges.list.empty.title)}
          subtitle={t(i18nKeys.challenges.list.empty.subtitle)}
        />
      ),
      noMatch: (
        <TableEmptyState
          title={t(i18nKeys.tables.noMatch.title)}
          subtitle={t(i18nKeys.tables.noMatch.subtitle)}
          onClearFilter={() => actions.setFiltering('')}
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    propertyFiltering: {
      filteringProperties,
    },
    sorting: {
      defaultState: {
        sortingColumn: COLUMN_DEFINITIONS(toggleChallengeInfo)[lastUsedColumnIndex],
        isDescending: false,
      },
    },
  });

  const handleChallengeSelection = (selectedChallengeItems: ChallengeListItem[]) => {
    setSelectedChallenges(selectedChallengeItems);
    if (handleChallengeAction) {
      handleChallengeAction(getChallengeDescriptorsToCopy(currentChallengeDescriptors, selectedChallengeItems));
    }
    if (showSelectedSplitPanel) {
      renderSplitPanelContent(
        t(i18nKeys.challenges.browseChallenges.selectedChallenges, {
          selectedChallengeCount: selectedChallengeItems.length,
        }),
        <BrowseChallengesSplitPanel selectedChallenges={selectedChallengeItems} />
      );
    }
  };

  const populateSelectedChallengesFromCurrentDescriptors = () => {
    const newSelectedChallenges: ChallengeListItem[] = [];
    currentChallengeDescriptors.map((challenge) => {
      if (challenge.challengeId) {
        const challengeIndex = challengeListItems?.findIndex((item) => item.challengeId === challenge.challengeId);
        if (challengeIndex && challengeListItems) {
          newSelectedChallenges.push(challengeListItems[challengeIndex]);
        }
      }
    });
    setSelectedChallenges(newSelectedChallenges);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleSetPreferences = (tablePreferences: CollectionPreferencesProps.Preferences) => {
    if (tablePreferences.pageSize) {
      sessionStorage.setItem(UserPreferenceKeys.PAGE_SIZE, tablePreferences.pageSize.toString());
    }
    if (tablePreferences.custom) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      sessionStorage.setItem(UserPreferenceKeys.DISPLAY_TIME, tablePreferences.custom);
    }
    setPreferences(tablePreferences);
  };

  return (
    <React.Fragment>
      {editMode ? (
        <Table
          {...collectionProps}
          variant="full-page"
          header={
            <TableHeader
              totalItems={challengesCount}
              selectedItems={selectedChallenges}
              showZero
              title={t(i18nKeys.challenges.title)}
            />
          }
          items={items || []}
          columnDefinitions={COLUMN_DEFINITIONS(toggleChallengeInfo)}
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={paginationLabels(t)}
            />
          }
          ariaLabels={{
            selectionGroupLabel: t(i18nKeys.challenges.selectionGroupLabel),
            allItemsSelectionLabel: ({ selectedItems }) =>
              `${selectedItems.length} ${selectedItems.length === 1 ? 'item' : 'items'} selected`,
            itemSelectionLabel: ({}, item: ChallengeListItem) => item.challengeId || '',
          }}
          selectionType="multi"
          loading={loadingOpenSearchChallenges}
          resizableColumns
          loadingText={t(i18nKeys.challenges.loadingChallenges)}
          selectedItems={selectedChallenges}
          onSelectionChange={({ detail }) => handleChallengeSelection(detail.selectedItems)}
          preferences={
            <CollectionPreferences
              title={t(i18nKeys.events.fields.preferences.title)}
              onConfirm={({ detail }) => handleSetPreferences(detail)}
              confirmLabel={t(i18nKeys.general.confirm)}
              cancelLabel={t(i18nKeys.general.cancel)}
              preferences={preferences}
              pageSizePreference={{
                title: t(i18nKeys.events.fields.preferences.pageSize.title),
                options: [
                  { value: 10, label: t(i18nKeys.challenges.fields.preferences.pageSize.label, { count: 10 }) },
                  { value: 20, label: t(i18nKeys.challenges.fields.preferences.pageSize.label, { count: 20 }) },
                  { value: 30, label: t(i18nKeys.challenges.fields.preferences.pageSize.label, { count: 30 }) },
                  { value: 50, label: t(i18nKeys.challenges.fields.preferences.pageSize.label, { count: 50 }) },
                  { value: 100, label: t(i18nKeys.challenges.fields.preferences.pageSize.label, { count: 100 }) },
                ],
              }}
            />
          }
          filter={
            <Box margin={{ top: 'xs', bottom: 'xs' }}>
              <PropertyFilter
                hideOperations
                {...propertyFilterProps}
                countText={
                  filteredItemsCount === 1 ?
                    t(i18nKeys.tables.matchesCount, { count: 1 }) :
                    t(i18nKeys.tables.matchesCount_plural, { count: filteredItemsCount })
                }
                i18nStrings={{
                  filteringAriaLabel: t(i18nKeys.eventTemplates.customTable.filter.filteringAriaLabel),
                  dismissAriaLabel: t(i18nKeys.eventTemplates.customTable.filter.dismissAriaLabel),
                  filteringPlaceholder: t(i18nKeys.eventTemplates.table.challangePlaceholder),
                  groupValuesText: t(i18nKeys.eventTemplates.customTable.filter.groupValuesText),
                  groupPropertiesText: t(i18nKeys.eventTemplates.customTable.filter.groupPropertiesText),
                  operatorsText: t(i18nKeys.eventTemplates.customTable.filter.operatorsText),
                  operationAndText: t(i18nKeys.eventTemplates.customTable.filter.operationAndText),
                  operationOrText: t(i18nKeys.eventTemplates.customTable.filter.operationOrText),
                  operatorLessText: t(i18nKeys.eventTemplates.customTable.filter.operatorLessText),
                  operatorLessOrEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorLessOrEqualText),
                  operatorGreaterText: t(i18nKeys.eventTemplates.customTable.filter.operatorGreaterText),
                  operatorGreaterOrEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorGreaterOrEqualText),
                  operatorContainsText: t(i18nKeys.eventTemplates.customTable.filter.operatorContainsText),
                  operatorDoesNotContainText: t(i18nKeys.eventTemplates.customTable.filter.operatorDoesNotContainText),
                  operatorEqualsText: t(i18nKeys.eventTemplates.customTable.filter.operatorEqualsText),
                  operatorDoesNotEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorDoesNotEqualText),
                  editTokenHeader: t(i18nKeys.eventTemplates.customTable.filter.editTokenHeader),
                  propertyText: t(i18nKeys.eventTemplates.customTable.filter.propertyText),
                  operatorText: t(i18nKeys.eventTemplates.customTable.filter.operatorText),
                  valueText: t(i18nKeys.eventTemplates.customTable.filter.valueText),
                  cancelActionText: t(i18nKeys.eventTemplates.customTable.filter.cancelActionText),
                  applyActionText: t(i18nKeys.eventTemplates.customTable.filter.applyActionText),
                  allPropertiesLabel: t(i18nKeys.eventTemplates.customTable.filter.allPropertiesLabel),
                  tokenLimitShowMore: t(i18nKeys.eventTemplates.customTable.filter.tokenLimitShowMore),
                  tokenLimitShowFewer: t(i18nKeys.eventTemplates.customTable.filter.tokenLimitShowFewer),
                  clearFiltersText: t(i18nKeys.eventTemplates.customTable.filter.clearFiltersText),
                }}
              />
            </Box>
          }
          // stickyColumns={{ first: 0, last: 1 }}
        />
      ) : (
        <Table
          {...collectionProps}
          variant="full-page"
          header={
            <TableHeader
              totalItems={challengesCount}
              title={t(i18nKeys.challenges.title)}
              actionButtons={allActionButtons}
            />
          }
          items={items || []}
          columnDefinitions={COLUMN_DEFINITIONS(toggleChallengeInfo, editMode)}
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={paginationLabels(t)}
            />
          }
          ariaLabels={{
            selectionGroupLabel: t(i18nKeys.challenges.selectionGroupLabel),
            allItemsSelectionLabel: ({ selectedItems }) =>
              `${selectedItems.length} ${selectedItems.length === 1 ? 'item' : 'items'} selected`,
            itemSelectionLabel: ({}, item: ChallengeListItem) => item.challengeId || '',
          }}
          loading={loadingOpenSearchChallenges}
          resizableColumns
          loadingText={t(i18nKeys.challenges.loadingChallenges)}
          preferences={
            <CollectionPreferences
              title={t(i18nKeys.events.fields.preferences.title)}
              onConfirm={({ detail }) => handleSetPreferences(detail)}
              confirmLabel={t(i18nKeys.general.confirm)}
              cancelLabel={t(i18nKeys.general.cancel)}
              preferences={preferences}
              pageSizePreference={{
                title: t(i18nKeys.events.fields.preferences.pageSize.title),
                options: [
                  { value: 10, label: t(i18nKeys.challenges.fields.preferences.pageSize.label, { count: 10 }) },
                  { value: 20, label: t(i18nKeys.challenges.fields.preferences.pageSize.label, { count: 20 }) },
                  { value: 30, label: t(i18nKeys.challenges.fields.preferences.pageSize.label, { count: 30 }) },
                  { value: 50, label: t(i18nKeys.challenges.fields.preferences.pageSize.label, { count: 50 }) },
                  { value: 100, label: t(i18nKeys.challenges.fields.preferences.pageSize.label, { count: 100 }) },
                ],
              }}
            />
          }
          filter={
            <Box margin={{ top: 'xs', bottom: 'xs' }}>
              <PropertyFilter
                hideOperations
                {...propertyFilterProps}
                countText={
                  filteredItemsCount === 1 ?
                    t(i18nKeys.tables.matchesCount, { count: 1 }) :
                    t(i18nKeys.tables.matchesCount_plural, { count: filteredItemsCount })
                }
                i18nStrings={{
                  filteringAriaLabel: t(i18nKeys.eventTemplates.customTable.filter.filteringAriaLabel),
                  dismissAriaLabel: t(i18nKeys.eventTemplates.customTable.filter.dismissAriaLabel),
                  filteringPlaceholder: t(i18nKeys.eventTemplates.table.challangePlaceholder),
                  groupValuesText: t(i18nKeys.eventTemplates.customTable.filter.groupValuesText),
                  groupPropertiesText: t(i18nKeys.eventTemplates.customTable.filter.groupPropertiesText),
                  operatorsText: t(i18nKeys.eventTemplates.customTable.filter.operatorsText),
                  operationAndText: t(i18nKeys.eventTemplates.customTable.filter.operationAndText),
                  operationOrText: t(i18nKeys.eventTemplates.customTable.filter.operationOrText),
                  operatorLessText: t(i18nKeys.eventTemplates.customTable.filter.operatorLessText),
                  operatorLessOrEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorLessOrEqualText),
                  operatorGreaterText: t(i18nKeys.eventTemplates.customTable.filter.operatorGreaterText),
                  operatorGreaterOrEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorGreaterOrEqualText),
                  operatorContainsText: t(i18nKeys.eventTemplates.customTable.filter.operatorContainsText),
                  operatorDoesNotContainText: t(i18nKeys.eventTemplates.customTable.filter.operatorDoesNotContainText),
                  operatorEqualsText: t(i18nKeys.eventTemplates.customTable.filter.operatorEqualsText),
                  operatorDoesNotEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorDoesNotEqualText),
                  editTokenHeader: t(i18nKeys.eventTemplates.customTable.filter.editTokenHeader),
                  propertyText: t(i18nKeys.eventTemplates.customTable.filter.propertyText),
                  operatorText: t(i18nKeys.eventTemplates.customTable.filter.operatorText),
                  valueText: t(i18nKeys.eventTemplates.customTable.filter.valueText),
                  cancelActionText: t(i18nKeys.eventTemplates.customTable.filter.cancelActionText),
                  applyActionText: t(i18nKeys.eventTemplates.customTable.filter.applyActionText),
                  allPropertiesLabel: t(i18nKeys.eventTemplates.customTable.filter.allPropertiesLabel),
                  tokenLimitShowMore: t(i18nKeys.eventTemplates.customTable.filter.tokenLimitShowMore),
                  tokenLimitShowFewer: t(i18nKeys.eventTemplates.customTable.filter.tokenLimitShowFewer),
                  clearFiltersText: t(i18nKeys.eventTemplates.customTable.filter.clearFiltersText),
                }}
              />
            </Box>
          }
          // stickyColumns={{ first: 0, last: 1 }}
        />
      )}
    </React.Fragment>
  );
};

export default BrowseChallengesOpenSearch;
