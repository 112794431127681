/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { cloneDeep, Dictionary, kebabCase, pick } from 'lodash';
import moment from 'moment-timezone';
import { isEmailListValid } from '../utils/string.utils';
import { EventType } from '../constants/shared/event-type';
import { EventAudienceType } from '../constants/shared/event-audience-type';
import { JamConstants } from '../constants/shared/jam-constants';
import {
  ChallengeConfiguration,
  ChallengeDescriptor,
  ChallengeFeedback,
  ChallengeFeedbackSummary,
  ChallengePrizeInformation,
  ChallengeWarning,
  ChallengeWrapper,
  getChallengesDiff,
  getChallengeWarnings,
  UpdateBackupChallengesEvent,
  WithChallengesAndScoring,
  ChallengeDifficulty,
} from './Challenge';
import { Team, TeamMember } from './Team';
import * as common from './common';
import { ApprovableDiff } from './ResourceDeployment';
import { ChallengeBoard } from './ChallengeBoard';
import { User } from './User';
import { DiffChange, diffObjects, DiffResult } from './Diff';
import { getCampaignUrl, getEventUrl, getTestEventUrl } from '../utils/jam-urls';
import * as EventTimeUtils from '../utils/event-time.utils';
import { stringifyTranslatedSSHWarning } from '../utils/stringify-warnings';
import { LabShutoffStatus } from './LabShutoff';
import { OnboardingVideoDetails } from './OnboardingVideo';
import { getAllowedRegionsForEvent } from '../utils/supported-regions';
import { AddErrorFlashbar } from '../store/flashbar.context';
import { preProdLogger } from '../utils/log.utils';
import { TFunctionProvider } from '../components/common/TFunctionProvider';
import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { ChangeRequest, WithApprovalAndChangeRequest } from './Approvable';
import _ from 'lodash';
import { SkillBuilderSubscription } from './SkillBuilderSubscription';
import { fromPlainObject } from '../utils/mapper.utils';
import { i18nKeys } from '../utils/i18n.utils';
import { EventPrivacyType } from './EventPrivacyType';

export enum TeamAssignmentType {
  SELF_FORMING = 'SELF_FORMING',
  PRE_CREATED = 'PRE_CREATED',
  SKILL_BASED = 'SKILL_BASED',
}

export enum EventStatus {
  NOT_STARTED = 'NOT_STARTED',
  ONGOING = 'ONGOING',
  ENDED = 'ENDED',
  CANCELLED = 'CANCELLED',

}

export enum ParticipantActions {
  RESET_PASSWORD = 'reset-password',
  DISABLE_ACCOUNT = 'disable-account',
}

export enum DownloadParticipantActions {
  CHALLENGE_RESOLVER_PARTICIPANTS = 'download-participants-who-solved-a-challenge',
  ALL_PARTICIPANTS_EMAIL = 'download-all-participant-emails',
}

export enum TeamActions {
  CHANGE_TEAM_ALIAS = 'change-team-alias',
  DISABLE_TEAM = 'disable-team',
  DELETE_TEAM = 'delete-team',
}

export const MARKETING_URL = 'https://aws.amazon.com/training/digital/aws-jam/';
export const DEFAULT_EVENT_FULL_MESSAGE = `Unfortunately, the Jam event you are trying to join has reached its max capacity and is not accepting any additional participants. Please visit ${MARKETING_URL} to learn about other events and opportunities. Keep Jammin'!`;

export const DEFAULT_MAX_TEAM_SIZE = 4;
export const DEFAULT_MIN_EXPECTED_PARTICIPANTS = 10;
export const DEFAULT_MAX_EXPECTED_PARTICIPANTS = 30;
export const DEFAULT_WARMUP_OFFSET_HOURS = 4;
export const DEFAULT_LAB_EXTENSION_HOURS = 2;
export const DEFAULT_LAB_AUTO_SCALE_MIN_PERCENT = 50;
export const DEFAULT_IS_TEAM_RENAME_ENABLED = true;
export const MIN_ALLOWED_TEAM_SIZE = 1;
export const MAX_ALLOWED_TEAM_SIZE = 50;
export const DEFAULT_EVENT_TYPE: string = EventType.JAM;
export const DEFAULT_AUDIENCE_TYPE: string = EventAudienceType.AWS_INTERNAL;
export const MAX_EXPECTED_PARTICIPANTS = 5000; // upper limit for expected participants
export const MIN_EXPECTED_PARTICIPANTS = 1; // lower limit for expected participants
export const MAX_DURATION_NON_ADMIN = 8; // max duration for non-admin user
export const MAX_WARMUP_OFFSET_HOURS = 24 * 7; // upper limit warmup hours (7 days)
export const MIN_WARMUP_OFFSET_HOURS = 1; // lower limit for warmup hours (1 hour)
export const MAX_LAB_EXTENSION_HOURS = 24 * 3; // upper limit lab extension hours (3 days)
export const MIN_LAB_EXTENSION_HOURS = 1; // lower limit for lab extension hours (1 hour)
export const MIN_LAB_AUTO_SCALE_MIN_PERCENT = 5; // lower limit for lab auto-scale min percent
export const MAX_LAB_AUTO_SCALE_MIN_PERCENT = 100; // upper limit for lab auto-scale min percent

export interface EventExclusions {
  excludeTest?: boolean;
  excludeOneClickTests?: boolean;
  excludeTestClones?: boolean;
  excludeCancelled?: boolean;
  excludeNeverApproved?: boolean;
}

export interface EventFilterOptions extends EventExclusions {
  dateRangeStart?: string;
  dateRangeEnd?: string;
}

export interface TinyEventBase {
  name: common.NullableString;
  id: common.NullableString;
  title: common.NullableString;
  startDate: common.NullableDateString;
  endDate: common.NullableDateString;
  timezone: common.NullableString;
}

export interface ITinyEvent extends TinyEventBase {
  labsAvailable: boolean;
  approved: boolean;
  cancelled: boolean;
}

@jsonObject
export class TinyEvent implements ITinyEvent {
  @jsonMember(String)
  name = '';

  @jsonMember(common.NullableStringValue)
  id: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  title: common.NullableString = null;

  @jsonMember(common.NullableDateStringValue)
  startDate: common.NullableDateString = null;

  @jsonMember(common.NullableDateStringValue)
  endDate: common.NullableDateString = null;

  @jsonMember(common.NullableStringValue)
  timezone: common.NullableDateString = null;

  @jsonMember(Boolean)
  labsAvailable = false;

  @jsonMember(Boolean)
  approved = false;

  @jsonMember(Boolean)
  cancelled = false;
}

@jsonObject
export class ChallengeExportLeadsEventResponse {
  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  eventPath: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  eventTitle: common.NullableString = null;
}

@jsonObject
export class EventScoringSettings {
  static readonly DEFAULT_CLUE_PENALTY_PERCENT_1: number = 10;
  static readonly DEFAULT_CLUE_PENALTY_PERCENT_2: number = 13;
  static readonly DEFAULT_CLUE_PENALTY_PERCENT_3: number = 15;

  @jsonMember(common.NullableNumberValue)
  easyScore: common.NullableNumber = ChallengeDifficulty.FUNDAMENTAL.defaultScore;

  @jsonMember(common.NullableNumberValue)
  mediumScore: common.NullableNumber = ChallengeDifficulty.INTERMEDIATE.defaultScore;

  @jsonMember(common.NullableNumberValue)
  hardScore: common.NullableNumber = ChallengeDifficulty.ADVANCED.defaultScore;

  @jsonMember(common.NullableNumberValue)
  expertScore: common.NullableNumber = ChallengeDifficulty.EXPERT.defaultScore;

  @jsonMember(common.NullableNumberValue)
  clue1PenaltyPercent: common.NullableNumber = EventScoringSettings.DEFAULT_CLUE_PENALTY_PERCENT_1;

  @jsonMember(common.NullableNumberValue)
  clue2PenaltyPercent: common.NullableNumber = EventScoringSettings.DEFAULT_CLUE_PENALTY_PERCENT_2;

  @jsonMember(common.NullableNumberValue)
  clue3PenaltyPercent: common.NullableNumber = EventScoringSettings.DEFAULT_CLUE_PENALTY_PERCENT_3;

  static diff(previousSettings: EventScoringSettings, updatedSettings: EventScoringSettings): DiffChange[] {
    const propertiesToDiff: string[] = Object.keys(new EventScoringSettings());
    return diffObjects(previousSettings, updatedSettings, propertiesToDiff);
  }
}

@jsonObject
export class CollaborationOptions {
  static readonly DEFAULT_SUPPORT_CHAT_ENABLED: boolean = false;
  static readonly DEFAULT_TEAM_CHAT_ENABLED: boolean = true;
  static readonly DEFAULT_MESSAGING_ENABLED: boolean = true;

  @jsonMember(Boolean)
  supportChatEnabled: boolean = CollaborationOptions.DEFAULT_SUPPORT_CHAT_ENABLED;

  @jsonMember(Boolean)
  teamChatEnabled: boolean = CollaborationOptions.DEFAULT_TEAM_CHAT_ENABLED;

  @jsonMember(Boolean)
  messagingEnabled: boolean = CollaborationOptions.DEFAULT_MESSAGING_ENABLED;

  static diff(previousOptions: CollaborationOptions, updatedOptions: CollaborationOptions): DiffChange[] {
    const propertiesToDiff: string[] = Object.keys(new CollaborationOptions());
    return diffObjects(
      Object.assign(new CollaborationOptions(), previousOptions),
      Object.assign(new CollaborationOptions(), updatedOptions),
      propertiesToDiff
    );
  }
}

/**
 * Interface to represent the properties of a public event, to be saved
 * in the backend as a PublicEventDetails object.
 * This interface should match the backend class PublicEventDetails.
 */
@jsonObject
export class PublicEventDetails {
  // this class is constructed with these defaults that may be overridden
  @jsonMember(Boolean)
  public visible = false;

  @jsonMember(common.NullableStringValue)
  public title: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  public description: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  public location: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  public moreInfoURL: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  public imageFilename: common.NullableString = null;
}

export const getEmailsFromEventPermissions = (permissions: EventPermission[], targetAttribute: string): string[] => {
  return permissions.filter((p: any) => p[targetAttribute]).map((p) => p.email);
};

export enum EventPermissionType {
  OWNER = 'OWNER',
  FACILITATOR = 'FACILITATOR',
  SUPPORT = 'SUPPORT',
}

@jsonObject
export class EventPermission {
  @jsonMember(String)
  email = '';
  @jsonMember(common.NullableStringValue)
  eventPermissionType: common.Nullable<EventPermissionType> = null;

  get isOwner() {
    return this.eventPermissionType === EventPermissionType.OWNER;
  }

  get isFacilitator() {
    return this.isOwner || this.eventPermissionType === EventPermissionType.FACILITATOR;
  }

  get isSupportUser() {
    return this.isFacilitator || this.eventPermissionType === EventPermissionType.SUPPORT;
  }

  static fromPlainObject(obj: object): EventPermission {
    return Object.assign(new EventPermission(), obj);
  }

  static diff(previousPermissions: EventPermission[], updatedPermissions: EventPermission[]): DiffChange[] {
    const previousOwners: string[] = getEmailsFromEventPermissions(previousPermissions, 'isOwner');
    const previousFacilitators: string[] = getEmailsFromEventPermissions(previousPermissions, 'isFacilitator');
    const previousSupport: string[] = getEmailsFromEventPermissions(previousPermissions, 'isSupportUser');

    const updatedOwners: string[] = getEmailsFromEventPermissions(updatedPermissions, 'isOwner');
    const updatedFacilitators: string[] = getEmailsFromEventPermissions(updatedPermissions, 'isFacilitator');
    const updatedSupport: string[] = getEmailsFromEventPermissions(updatedPermissions, 'isSupportUser');

    const changes: DiffChange[] = [];

    if (!_.isEqual(previousOwners, updatedOwners)) {
      changes.push(
        Object.assign(new DiffChange(), {
          property: 'Owners',
          previousValue: previousOwners,
          updatedValue: updatedOwners,
        })
      );
    }
    if (!_.isEqual(previousFacilitators, updatedFacilitators)) {
      changes.push(
        Object.assign(new DiffChange(), {
          property: 'Facilitators',
          previousValue: previousFacilitators,
          updatedValue: updatedFacilitators,
        })
      );
    }
    if (!_.isEqual(previousSupport, updatedSupport)) {
      changes.push(
        Object.assign(new DiffChange(), {
          property: 'Support',
          previousValue: previousSupport,
          updatedValue: updatedSupport,
        })
      );
    }

    return changes;
  }
}

export interface PublicEditableEventProps {
  agreementId: common.NullableString;
  startDate: common.NullableDateString;
  endDate: common.NullableDateString;
  autoUnlockChallengesOffsetMinutes: common.NullableNumber;
  teamRenameEnabled: boolean;
  timezone: common.NullableString;
  title: common.NullableString;
  description: common.NullableString;
  type: common.NullableString;
  audienceType: common.NullableString;
  eventPermissions: EventPermission[];
  notes: common.NullableString;
  maxTeamSize: common.NullableNumber;
  minExpectedParticipants: common.NullableNumber;
  maxExpectedParticipants: common.NullableNumber;
  teamAssignmentType: common.Nullable<TeamAssignmentType>;
  test: common.Nullable<boolean>;
  testCloneSuffix: common.NullableString;
  preApprovedFacilitators: string[];
  owners: string[];
  facilitatorDomainAllowlist: string[];
  participantDomainAllowlist: string[];
  challengeDescriptors: ChallengeDescriptor[];
  prizeInformation: common.Nullable<ChallengePrizeInformation>;
  collaborationOptions: CollaborationOptions;
  chimeWebHookUrl: common.NullableString;
  validSkillBuilderSubscriptions: SkillBuilderSubscription[];
  uiTheme: 'DARK' | 'LIGHT';
  challengeViewType: 'map' | 'list';
  scoringSettings: EventScoringSettings;
  gamified: boolean;
  publicEventDetails: PublicEventDetails;
}

@jsonObject
export class EventWarnings {
  @jsonMember(AnyT)
  allWarnings: { [warning: string]: string | boolean } = {};

  @jsonMember(AnyT)
  challengeWarnings: { [warning: string]: string } = {};

  @jsonMember(Number)
  numChallengesRequireSSH = 0;

  get warningStyle(): 'warning' | 'danger' {
    return Object.keys(this.allWarnings).length > Object.keys(this.challengeWarnings).length ? 'danger' : 'warning';
  }

  get hasWarnings(): boolean {
    return Object.keys(this.allWarnings).length > 0;
  }

  get hasChallengeWarnings(): boolean {
    return Object.keys(this.challengeWarnings).length > 0;
  }

  get hasSSHChallenges(): boolean {
    return this.numChallengesRequireSSH > 0;
  }

  get sshWarning(): common.NullableString {
    if (this.hasSSHChallenges) {
      const prefix = `${this.numChallengesRequireSSH} ${
        this.numChallengesRequireSSH > 1 ? 'challenges require' : 'challenge requires'
      }`;
      return stringifyTranslatedSSHWarning(TFunctionProvider, prefix);
    }
    return null;
  }
}

@jsonObject
export class BackupChallengeConfig {
  @jsonArrayMember(ChallengeDescriptor)
  generalBackups: ChallengeDescriptor[] = [];
  @jsonMember(AnyT)
  perChallengeBackups: Record<string, ChallengeDescriptor[]> = {};

  public static fromPlainObject(obj: any): BackupChallengeConfig {
    const config: BackupChallengeConfig = Object.assign(new BackupChallengeConfig(), obj) as BackupChallengeConfig;

    config.generalBackups = config.generalBackups.map(
      (challenge) => fromPlainObject(challenge, ChallengeDescriptor) as ChallengeDescriptor
    );
    config.perChallengeBackups = config.perChallengeBackups;

    Object.entries(config.perChallengeBackups).forEach((entry: [string, ChallengeDescriptor[]]) => {
      const challengeId = entry[0];
      const backups = entry[1] || [];

      config.perChallengeBackups[challengeId] = backups.map(
        (backup) => fromPlainObject(backup, ChallengeDescriptor) as ChallengeDescriptor
      );
    });

    return config;
  }

  private static addBackups(backups: ChallengeDescriptor[], newBackups: ChallengeDescriptor[]) {
    const filteredBackups = newBackups.filter((challengeDescriptor) => {
      const foundIndex = backups.findIndex((backup) => backup.challengeId === challengeDescriptor.challengeId);
      return foundIndex < 0;
    });
    backups.push(...filteredBackups);
  }

  public static diff(previous: BackupChallengeConfig, updated: BackupChallengeConfig): DiffChange[] {
    return diffObjects(
      BackupChallengeConfig.fromPlainObject(previous),
      BackupChallengeConfig.fromPlainObject(updated),
      Object.keys(new BackupChallengeConfig())
    );
  }

  public addGeneralBackups(backups: ChallengeDescriptor[]) {
    this.generalBackups = this.generalBackups ?? [];
    BackupChallengeConfig.addBackups(this.generalBackups, backups);
  }

  public addPerChallengeBackups(challengeId: string, backups: ChallengeDescriptor[]) {
    this.perChallengeBackups[challengeId] = this.perChallengeBackups[challengeId] ?? [];
    BackupChallengeConfig.addBackups(this.perChallengeBackups[challengeId], backups);
  }

  public removePerChallengeBackups(challengeId: string) {
    delete this.perChallengeBackups[challengeId];
  }

  public onUpdate(event: UpdateBackupChallengesEvent) {
    if (event.forChallengeId == null) {
      this.generalBackups = event.newBackupChallenges ?? [];
    } else {
      this.perChallengeBackups[event.forChallengeId] = event.newBackupChallenges ?? [];
    }
  }

  /**
   * Get all backups in this configuration.
   * May contain duplicates if the same backup appears in more than one backup queue.
   */
  public get allBackups(): ChallengeDescriptor[] {
    const backups = [...this.generalBackups];

    Object.values(this.perChallengeBackups).forEach((queue) => backups.push(...queue));

    return backups;
  }
}

@jsonObject
export class JamEventRequest implements PublicEditableEventProps {
  @jsonMember(common.NullableStringValue)
  agreementId: common.NullableString = null;

  @jsonArrayMember(String)
  validSkillBuilderSubscriptions: SkillBuilderSubscription[] = [];

  @jsonMember(common.NullableDateStringValue)
  startDate: common.NullableDateString = null;

  @jsonMember(common.NullableDateStringValue)
  endDate: common.NullableDateString = null;

  @jsonMember(common.NullableNumberValue)
  autoUnlockChallengesOffsetMinutes: common.NullableNumber = null;

  @jsonMember(Boolean)
  teamRenameEnabled: boolean = DEFAULT_IS_TEAM_RENAME_ENABLED;

  @jsonMember(common.NullableStringValue)
  timezone: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  title: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  description: common.NullableString = null;

  @jsonMember(String)
  type: string = DEFAULT_EVENT_TYPE;

  @jsonMember(common.NullableStringValue)
  audienceType: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  notes: common.NullableString = null;

  @jsonMember(common.NullableNumberValue)
  maxTeamSize: common.NullableNumber = DEFAULT_MAX_TEAM_SIZE;

  @jsonMember(common.NullableNumberValue)
  minExpectedParticipants: common.NullableNumber = DEFAULT_MIN_EXPECTED_PARTICIPANTS;

  @jsonMember(common.NullableNumberValue)
  maxExpectedParticipants: common.NullableNumber = DEFAULT_MAX_EXPECTED_PARTICIPANTS;

  @jsonArrayMember(EventPermission)
  eventPermissions: EventPermission[] = [];

  @jsonMember(common.NullableStringValue)
  teamAssignmentType: common.Nullable<TeamAssignmentType> = null;

  @jsonMember(common.NullableBooleanValue)
  test: common.NullableBoolean = null;

  @jsonMember(common.NullableStringValue)
  testCloneSuffix: common.NullableString = null;

  @jsonArrayMember(String)
  preApprovedFacilitators: string[] = [];

  @jsonArrayMember(String)
  owners: string[] = [];

  @jsonArrayMember(String)
  facilitatorDomainAllowlist: string[] = [];

  @jsonArrayMember(String)
  participantDomainAllowlist: string[] = [];

  @jsonArrayMember(ChallengeDescriptor)
  challengeDescriptors: ChallengeDescriptor[] = [];

  @jsonMember(BackupChallengeConfig)
  backupChallengeConfig?: BackupChallengeConfig = new BackupChallengeConfig();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  @jsonMember(common.NullableClassValue(ChallengePrizeInformation))
  prizeInformation: common.Nullable<ChallengePrizeInformation> = null;

  @jsonMember(CollaborationOptions)
  collaborationOptions: CollaborationOptions = new CollaborationOptions();

  @jsonMember(common.NullableStringValue)
  chimeWebHookUrl: common.NullableString = null;

  @jsonMember(String)
  uiTheme: 'DARK' | 'LIGHT' = 'DARK';

  @jsonMember(String)
  challengeViewType: 'map' | 'list' = 'map';

  @jsonMember(EventScoringSettings)
  scoringSettings: EventScoringSettings = new EventScoringSettings();

  @jsonMember(PublicEventDetails)
  publicEventDetails: PublicEventDetails = new PublicEventDetails();

  @jsonMember(common.NullableNumberValue)
  formTeamsMinsBeforeEventStart: common.NullableNumber = null;

  @jsonMember(Boolean)
  gamified = true;

  @jsonMember(Boolean)
  codeWhispererDisabled = false;

  @jsonMember(String)
  catalogId: common.NullableString = null;

  @jsonMember(common.NullableBooleanValue)
  testEventPublic: common.NullableBoolean = null;

  @jsonMember(common.NullableStringValue)
  eventPrivacyType: EventPrivacyType | null = null;

  @jsonMember(common.NullableStringValue)
  eventCode: common.NullableString = null;

  static setDefaults(user: User, event: JamEventRequest, eventConfig: EventConfiguration) {
    if (event.audienceType == null) {
      if (user && user.isAmazonian) {
        event.audienceType = DEFAULT_AUDIENCE_TYPE;
      } else {
        event.audienceType = EventAudienceType.CUSTOMER_INTERNAL;
      }
    }

    if (event.teamAssignmentType == null) {
      event.teamAssignmentType = eventConfig.defaultTeamAssignmentType;
    }
    if (event.formTeamsMinsBeforeEventStart == null) {
      event.formTeamsMinsBeforeEventStart = eventConfig.defaultFormTeamsMinsBeforeEventStart;
    }
  }

  static fromEvent(event: Event): JamEventRequest {
    return Object.assign(new JamEventRequest(), pick(event, Object.keys(new JamEventRequest())));
  }

  diffFromEvent(user: User, event: EventBase, eventConfig: EventConfiguration): DiffChange[] {
    EventBase.setDefaults(user, event, eventConfig);
    JamEventRequest.setDefaults(user, this, eventConfig);

    const propertiesToDiff: string[] = Object.keys(new JamEventRequest()).filter((property) => {
      if (property === 'challengeDescriptors') {
        return false;
      }
      if (property === 'prizeInformation') {
        return false;
      }
      if (property === 'test') {
        return false;
      }
      if (property === 'testCloneSuffix') {
        return false;
      }
      if (property === 'collaborationOptions') {
        return false;
      }
      if (property === 'eventPermissions') {
        return false;
      }
      if (property === 'backupChallengeConfig') {
        return false;
      }
      return true;
    });

    const changes: DiffChange[] = [
      ...diffObjects(event, this, propertiesToDiff, Event.PROPERTY_LABEL_OVERRIDES),
      ...CollaborationOptions.diff(event.collaborationOptions, this.collaborationOptions),
      ...getChallengesDiff(event.challengeDescriptors, this.challengeDescriptors),
      ...EventPermission.diff(event.eventPermissions, this.eventPermissions),
      ...BackupChallengeConfig.diff(
        event.backupChallengeConfig || new BackupChallengeConfig(),
        this.backupChallengeConfig || new BackupChallengeConfig()
      ),
    ];

    // handle prize information changes
    // prizeInformation.prizeCount
    // prizeInformation.maxClues

    const oldPrizeInfo = event.prizeInformation;
    const newPrizeInfo = this.prizeInformation || new ChallengePrizeInformation();

    if (oldPrizeInfo.prizeCount !== newPrizeInfo.prizeCount) {
      // prize count changed
      changes.push({
        property: 'Prizes: Prize Count',
        previousValue: oldPrizeInfo.prizeCount,
        updatedValue: newPrizeInfo.prizeCount,
      });
    }

    if (oldPrizeInfo.maxClues !== newPrizeInfo.maxClues) {
      // max clues
      changes.push({
        property: 'Prizes: Clues Allowed',
        previousValue: oldPrizeInfo.maxClues,
        updatedValue: newPrizeInfo.maxClues,
      });
    }

    if (this.test !== event.test) {
      changes.push({
        property: 'Test Event',
        previousValue: event.test,
        updatedValue: this.test,
      });
    }

    const TEST_ONLY = 'Test Event Only';
    const LIVE_AND_TEST = 'Live Event & Test Event';
    const LIVE_ONLY = 'Live Event Only';

    const previousTestValue = event.test ? TEST_ONLY : event.testCloneSuffix ? LIVE_AND_TEST : LIVE_ONLY;
    const updatedTestValue = this.test ? TEST_ONLY : this.testCloneSuffix ? LIVE_AND_TEST : LIVE_ONLY;

    if (previousTestValue !== updatedTestValue) {
      changes.push({
        property: 'Testing',
        previousValue: previousTestValue,
        updatedValue: updatedTestValue,
      });
    }

    return changes;
  }
}

@jsonObject
export class EventChangeRequest extends JamEventRequest implements ChangeRequest {
  @jsonMember(String)
  status: common.ChangeRequestStatus = common.ChangeRequestStatus.CHANGE_REQUESTED;

  @jsonMember(common.NullableStringValue)
  requestedBy: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  requestResolvedBy: common.NullableString = null;

  @jsonMember(common.NullableTimeStampValue)
  createdDate: common.NullableTimeStamp = null;

  @jsonMember(common.NullableTimeStampValue)
  lastUpdatedDate: common.NullableTimeStamp = null;

  get pending() {
    return (
      this.status === common.ChangeRequestStatus.CHANGE_REQUESTED ||
      this.status === common.ChangeRequestStatus.CHANGE_PENDING
    );
  }

  get resolved() {
    return (
      this.status === common.ChangeRequestStatus.CHANGE_APPROVED ||
      this.status === common.ChangeRequestStatus.CHANGE_DENIED ||
      this.status === common.ChangeRequestStatus.CHANGE_CANCELLED
    );
  }
}

@jsonObject
export class EventSponsorshipSettings {
  static readonly DEFAULT_FIRST_NAME_REQUIRED: common.NullableBoolean = true;
  static readonly DEFAULT_LAST_NAME_REQUIRED: common.NullableBoolean = true;
  static readonly DEFAULT_PHONE_NUMBER_REQUIRED: common.NullableBoolean = true;
  static readonly DEFAULT_ADDRESS_REQUIRED: common.NullableBoolean = true;
  static readonly DEFAULT_TITLE_REQUIRED: common.NullableBoolean = true;
  static readonly DEFAULT_COMPANY_REQUIRED: common.NullableBoolean = true;
  static readonly DEFAULT_COUNTRY_REQUIRED: common.NullableBoolean = true;
  static readonly DEFAULT_POSTAL_CODE_REQUIRED: common.NullableBoolean = true;

  @jsonMember(common.NullableBooleanValue)
  sponsored = false;

  @jsonMember(common.NullableBooleanValue)
  firstNameRequired: common.NullableBoolean = null;

  @jsonMember(common.NullableBooleanValue)
  lastNameRequired: common.NullableBoolean = null;

  @jsonMember(common.NullableBooleanValue)
  phoneNumberRequired: common.NullableBoolean = null;

  @jsonMember(common.NullableBooleanValue)
  addressRequired: common.NullableBoolean = null;

  @jsonMember(common.NullableBooleanValue)
  titleRequired: common.NullableBoolean = null;

  @jsonMember(common.NullableBooleanValue)
  companyRequired: common.NullableBoolean = null;

  @jsonMember(common.NullableBooleanValue)
  countryRequired: common.NullableBoolean = null;

  @jsonMember(common.NullableBooleanValue)
  postalCodeRequired: common.NullableBoolean = null;

  withDefaults(): EventSponsorshipSettings {
    if (this.sponsored == null) {
      this.sponsored = false;
    }
    // Other settings are relevant only for sponsored events
    if (this.sponsored) {
      if (this.firstNameRequired == null) {
        this.firstNameRequired = EventSponsorshipSettings.DEFAULT_FIRST_NAME_REQUIRED;
      }
      if (this.lastNameRequired == null) {
        this.lastNameRequired = EventSponsorshipSettings.DEFAULT_LAST_NAME_REQUIRED;
      }
      if (this.phoneNumberRequired == null) {
        this.phoneNumberRequired = EventSponsorshipSettings.DEFAULT_PHONE_NUMBER_REQUIRED;
      }
      if (this.addressRequired == null) {
        this.addressRequired = EventSponsorshipSettings.DEFAULT_ADDRESS_REQUIRED;
      }
      if (this.titleRequired == null) {
        this.titleRequired = EventSponsorshipSettings.DEFAULT_TITLE_REQUIRED;
      }
      if (this.companyRequired == null) {
        this.companyRequired = EventSponsorshipSettings.DEFAULT_COMPANY_REQUIRED;
      }
      if (this.countryRequired == null) {
        this.countryRequired = EventSponsorshipSettings.DEFAULT_COUNTRY_REQUIRED;
      }
      if (this.postalCodeRequired == null) {
        this.postalCodeRequired = EventSponsorshipSettings.DEFAULT_POSTAL_CODE_REQUIRED;
      }
    }
    return this;
  }

  defaultForSponsoredEvents(): EventSponsorshipSettings {
    this.sponsored = true;
    this.firstNameRequired = true;
    this.lastNameRequired = true;
    this.phoneNumberRequired = true;
    this.addressRequired = true;
    this.titleRequired = true;
    this.companyRequired = true;
    this.countryRequired = true;
    this.postalCodeRequired = true;
    return this;
  }

  defaultForNonSponsoredEvents(): EventSponsorshipSettings {
    this.sponsored = false;
    this.firstNameRequired = false;
    this.lastNameRequired = false;
    this.phoneNumberRequired = false;
    this.addressRequired = false;
    this.titleRequired = false;
    this.companyRequired = false;
    this.countryRequired = false;
    this.postalCodeRequired = false;
    return this;
  }
}

@jsonObject
export class EventBase extends WithApprovalAndChangeRequest implements TinyEventBase, PublicEditableEventProps {
  static readonly PROPERTY_LABEL_OVERRIDES = {
    maxTeamSize: 'Team Size',
    preApprovedFacilitators: 'Facilitators',
    owners: 'Event Owners',
    participantDomainAllowlist: 'Registration Email Allowlist',
    facilitatorDomainAllowlist: 'Facilitator Email Allowlist',
  };

  @jsonMember(common.NullableStringValue)
  agreementId: common.NullableString = null;

  @jsonMember(String)
  name = '';

  @jsonMember(common.NullableStringValue)
  id: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  title: common.NullableString = null;

  @jsonMember(common.NullableDateStringValue)
  startDate: common.NullableDateString = null;

  @jsonMember(common.NullableDateStringValue)
  endDate: common.NullableDateString = null;

  @jsonArrayMember(EventPermission)
  eventPermissions: EventPermission[] = [];

  @jsonMember(common.NullableStringValue)
  timezone: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  requestedBy: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  requestResolvedBy: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  cancelledBy: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  lastUpdatedBy: common.NullableString = null;

  @jsonMember(common.NullableTimeStampValue)
  createdDate: common.NullableTimeStamp = null;

  @jsonMember(common.NullableTimeStampValue)
  lastUpdatedDate: common.NullableTimeStamp = null;

  @jsonMember(common.NullableTimeStampValue)
  cancellationTime: common.NullableTimeStamp = null;

  @jsonMember(common.NullableStringValue)
  description: common.NullableString = null;

  @jsonMember(String)
  type: string = DEFAULT_EVENT_TYPE;

  @jsonMember(common.NullableStringValue)
  audienceType: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  notes: common.NullableString = null;

  @jsonMember(String)
  status: EventStatus = EventStatus.NOT_STARTED;

  @jsonMember(common.NullableStringValue)
  campaignId: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  campaignGroupId: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  approvalStatus: common.Nullable<common.ApprovalStatus> = null;

  @jsonMember(EventChangeRequest)
  changeRequest: common.Nullable<EventChangeRequest> = null;

  @jsonArrayMember(EventChangeRequest)
  changeRequests: EventChangeRequest[] = [];

  @jsonArrayMember(ApprovableDiff)
  changeHistory: ApprovableDiff[] = [];

  @jsonArrayMember(ChallengeDescriptor)
  challengeDescriptors: ChallengeDescriptor[] = [];

  @jsonMember(BackupChallengeConfig)
  backupChallengeConfig?: BackupChallengeConfig = new BackupChallengeConfig();

  @jsonArrayMember(ChallengeBoard)
  challengeBoards: ChallengeBoard[] = [];

  @jsonMember(Number)
  maxTeamSize: number = DEFAULT_MAX_TEAM_SIZE;

  @jsonMember(common.NullableNumberValue)
  minExpectedParticipants: common.NullableNumber = DEFAULT_MIN_EXPECTED_PARTICIPANTS;

  @jsonMember(common.NullableNumberValue)
  maxExpectedParticipants: common.NullableNumber = DEFAULT_MAX_EXPECTED_PARTICIPANTS;

  @jsonMember(Number)
  warmupOffsetHours: number = DEFAULT_WARMUP_OFFSET_HOURS;

  @jsonMember(Number)
  labExtensionHours: number = DEFAULT_LAB_EXTENSION_HOURS;

  @jsonMember(Number)
  labAutoScaleMinPercent: number = DEFAULT_LAB_AUTO_SCALE_MIN_PERCENT;

  @jsonMember(String)
  challengeViewType: 'map' | 'list' = 'map';

  @jsonMember(String)
  uiTheme: 'DARK' | 'LIGHT' = 'DARK';

  @jsonMember(common.NullableStringValue)
  eventCode: common.NullableString = null;

  @jsonMember(EventScoringSettings)
  scoringSettings: EventScoringSettings = new EventScoringSettings();

  @jsonArrayMember(String)
  validSkillBuilderSubscriptions: SkillBuilderSubscription[] = [];

  @jsonMember(Boolean)
  archived = false;

  @jsonMember(Boolean)
  closedForNewRegistrations = false;

  @jsonMember(common.NullableStringValue)
  teamAssignmentType: common.Nullable<TeamAssignmentType> = null;

  @jsonMember(Boolean)
  test = false;

  @jsonMember(common.NullableStringValue)
  testCloneSuffix: common.NullableString = null;

  @jsonMember(Number)
  testingDays = 7;

  @jsonMember(common.NullableStringValue)
  clonedFrom: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  testChallengeId: common.NullableString = null; // for one-click test events

  @jsonMember(Boolean)
  oneClickTestEvent = false;

  @jsonMember(common.NullableStringValue)
  testCloneCode: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  testCloneEventName: common.NullableString = null;

  @jsonArrayMember(String)
  facilitatorDomainAllowlist: string[] = [];

  @jsonArrayMember(String)
  participantDomainAllowlist: string[] = [];

  @jsonArrayMember(String)
  preApprovedFacilitators: string[] = [];

  @jsonArrayMember(String)
  owners: string[] = [];

  @jsonArrayMember(String)
  regionAllowlist: string[] = [];

  @jsonArrayMember(String)
  regionDenylist: string[] = [];

  @jsonArrayMember(common.Comment)
  comments: common.Comment[] = [];

  @jsonArrayMember(String)
  tags: string[] = [];

  @jsonMember(ChallengePrizeInformation)
  prizeInformation: ChallengePrizeInformation = new ChallengePrizeInformation();

  @jsonMember(CollaborationOptions)
  collaborationOptions: CollaborationOptions = new CollaborationOptions();

  @jsonMember(common.NullableStringValue)
  chimeWebHookUrl: common.NullableString = null;

  @jsonMember(PublicEventDetails)
  publicEventDetails: PublicEventDetails = new PublicEventDetails();

  @jsonMember(EventSponsorshipSettings)
  sponsorshipSettings: EventSponsorshipSettings = new EventSponsorshipSettings();

  @jsonMember(OnboardingVideoDetails)
  onboardingVideo: OnboardingVideoDetails = new OnboardingVideoDetails();

  @jsonMember(common.NullableNumberValue)
  formTeamsMinsBeforeEventStart: common.NullableNumber = null;

  @jsonMember(common.NullableNumberValue)
  autoUnlockChallengesOffsetMinutes: common.NullableNumber = null;

  @jsonMember(Boolean)
  teamRenameEnabled: boolean = DEFAULT_IS_TEAM_RENAME_ENABLED;

  @jsonMember(String)
  eventFullMessage: string = DEFAULT_EVENT_FULL_MESSAGE;

  /**
   * Shutoff status of this event (non-test).
   */
  @jsonMember(LabShutoffStatus)
  shutoffStatus?: LabShutoffStatus;

  /**
   * Shutoff status of this event's corresponding test event.
   */
  @jsonMember(LabShutoffStatus)
  testEventShutoffStatus?: LabShutoffStatus;

  // ui-only, not persisted
  @jsonMember(EventWarnings)
  warnings: EventWarnings = new EventWarnings();

  /* Determines whether an event is gamified. Should never be null. */
  @jsonMember(Boolean)
  gamified = true;

  @jsonMember(common.NullableBooleanValue)
  testEventPublic: common.NullableBoolean = null;

  @jsonMember(common.NullableStringValue)
  usagePlanId: common.NullableString = null;

  @jsonMember(Boolean)
  codeWhispererDisabled = false;

  @jsonMember(common.NullableStringValue)
  catalogId: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  paymentStatus: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  eventPrivacyType: EventPrivacyType | null = null;

  static forCreate(user: User, eventConfig: EventConfiguration): Event {
    // some default values are only set on creation of a new event.
    const defaultsOnlySetOnCreate = {
      testCloneSuffix: 'test',
    };
    return Event.setDefaults(user, Object.assign(new Event(), defaultsOnlySetOnCreate), eventConfig);
  }

  static diff(user: User, eventConfig: EventConfiguration, previousEvent: Event, updatedEvent: Event): DiffResult {
    const excludedProperties: string[] = ['lastUpdatedDate', 'comments', 'warnings'];

    return DiffResult.of(
      previousEvent.withDefaults(user, eventConfig),
      updatedEvent.withDefaults(user, eventConfig),
      Object.keys(new Event()).filter((property) => !excludedProperties.includes(property))
    );
  }

  static applyDiff(event: Event, diff: any): Event {
    return Object.assign(event, diff) as Event;
  }

  /**
   * This method sets the default values for any event attribute that could potentially be missing.
   *
   * --------------------------------------------------------------------------------------------
   * --------------------------------------------------------------------------------------------
   * --------------------------------------------------------------------------------------------
   * WARNING: NEVER INCLUDE AN ATTRIBUTE IN THIS METHOD IF NULL IS AN ACCEPTABLE VALUE,
   * see Event.forCreate for setting initial defaults.
   * --------------------------------------------------------------------------------------------
   * --------------------------------------------------------------------------------------------
   * --------------------------------------------------------------------------------------------
   *
   * @param user
   * @param event
   * @param eventConfig
   */
  static setDefaults<T extends EventBase>(user: User, event: T, eventConfig: EventConfiguration): T {
    if (event.audienceType == null) {
      if (user && user.isAmazonian) {
        event.audienceType = DEFAULT_AUDIENCE_TYPE;
      } else {
        event.audienceType = EventAudienceType.CUSTOMER_INTERNAL;
      }
    }

    if (event.codeWhispererDisabled == null) {
      event.codeWhispererDisabled = false;
    }

    if (!event.timezone) {
      event.timezone = moment.tz.guess(true);
      preProdLogger('guessed timezone', event.timezone);
    }

    if (event.teamAssignmentType == null) {
      event.teamAssignmentType = eventConfig.defaultTeamAssignmentType;
    }

    if (event.formTeamsMinsBeforeEventStart == null) {
      event.formTeamsMinsBeforeEventStart = eventConfig.defaultFormTeamsMinsBeforeEventStart;
    }

    return event;
  }

  static getMomentFromEventDate(dateTime: string) {
    let date = moment().minutes(0).seconds(0).add(24, 'hours');

    if (dateTime) {
      date = moment.parseZone(dateTime);
    }

    return date;
  }

  get duration() {
    if (!this.startDate || !this.endDate) {
      return {
        hours: 0,
        minutes: 0,
      };
    }

    const start = Event.getMomentFromEventDate(this.startDate);

    // add one second because otherwise the duration comes out to X hours and 59 minutes
    const endPlusOneSecond = Event.getMomentFromEventDate(this.endDate).add(1, 'seconds');
    const hours = Math.abs(start.diff(endPlusOneSecond, 'hours'));
    const startPlusHours = start.add(hours, 'hours');
    const minutes = Math.abs(startPlusHours.diff(endPlusOneSecond, 'minutes'));

    return {
      hours,
      minutes,
    };
  }

  /**
   * Determine whether this event can have backup challenges.
   * NOTE: this method must stay in sync with `AWSJam_GameBackend/.../Event.java#canHaveChallengesRevoked`.
   */
  get canHaveBackupChallenges(): boolean {
    return !this.test && !this.isCampaignEvent;
  }

  removeTag(tag: string) {
    this.tags = [...this.tags.filter((t) => t !== tag)];
  }

  addTag(tag: string) {
    this.removeTag(tag);
    this.tags = [...this.tags, kebabCase(tag)];
  }

  get isCampaignEvent(): boolean {
    return this.type === EventType.CAMPAIGN_GROUP;
  }

  get isSplEvent(): boolean {
    return this.type === EventType.SPL;
  }

  isAdmin(user: common.Nullable<User>): boolean {
    if (user) {
      return user && user.isEventAdmin;
    } else {
      return false;
    }
  }

  isGuestUser(user: common.Nullable<User>): boolean {
    if (user) {
      return user && user.isOnlyBasicUser;
    } else {
      return false;
    }
  }

  isOwner(user: User): boolean | string {
    return user && this.hasOwner(user.email);
  }

  isEventOwner(user: User): boolean | string {
    return user?.isEventAdmin || this.isEventRequestor(user) || !!this.isOwner;
  }

  hasOwner(email: string): string | boolean {
    return email && this.owners && this.owners.some((e) => e.toLowerCase() === email.toLowerCase());
  }

  isEventRequestor(user: User): boolean | null | '' {
    return user && user.email && this.requestedBy && this.requestedBy.toLowerCase() === user.email.toLowerCase();
  }

  get isSkillBasedAssignmentEnabled() {
    return this.teamAssignmentType === TeamAssignmentType.SKILL_BASED;
  }

  get isTeamsAutoAssigned() {
    return this.isSkillBasedAssignmentEnabled;
  }

  get isClone() {
    return this.clonedFrom != null;
  }

  get isNew() {
    return this.name === '';
  }

  get hasClone() {
    return this.testCloneSuffix != null;
  }

  get readonly(): boolean {
    return this.isClone || this.isCampaignEvent;
  }

  get final(): boolean {
    return this.ended || this.cancelled || this.denied;
  }

  /**
   * Get the list of allowlisted regionIds that are not also in the denylist,
   * falling back to the list of supported lab regions for the allowlist.
   */
  getAllowedRegions(challengeConfiguration?: ChallengeConfiguration): string[] | undefined {
    if (!challengeConfiguration) {
      return undefined;
    }

    return getAllowedRegionsForEvent(this, challengeConfiguration);
  }

  canEditAttribute(attribute: string, user: User): boolean {
    // check if the event is in a final state
    if (this.final) {
      return this.canEditFinalAttribute(attribute, user);
    }

    // check if this is a readonly event
    if (this.readonly) {
      return this.canEditReadOnlyEvent(attribute, user);
    }

    // check if this is a 1-click test event
    if (this.oneClickTestEvent) {
      return this.canEditOneClickTestEvent(attribute, user);
    }

    // check if this has an event change request
    if (this.pendingChangeRequest && !user.isEventAdmin) {
      // if there's a pending change request, since we don't allow
      // updating a change request, treat event as read only.
      // They can cancel the change request and create a new
      // one if they need to make changes.
      return this.canEditReadOnlyEvent(attribute, user);
    }

    // at this point we know the event is NOT in a final state.
    switch (attribute) {
      case 'eventCode':
      case 'eventCodeGenerator':
      case 'importTeamProperties':
      case 'userSupportedChallenges':
      case 'sponsored':
      case 'eventFullMessage':
        return user.isEventAdmin;
      case 'eventPermissions':
        return !this.isCampaignEvent && !this.isClone;
      case 'backupChallengeConfig':
        return this.canHaveBackupChallenges;
      default:
        // all other attributes are editable since the event is NOT final
        return true;
    }
  }

  /**
   * We must assume that this method was called knowing in advance that the event is read only.
   *
   * @param attribute
   * @param user
   */
  private canEditReadOnlyEvent(attribute: string, user: User) {
    switch (attribute) {
      case 'userSupportedChallenges':
        return user.isEventAdmin;
      case 'users':
      case 'teams':
        // always allow editing teams and users
        return true;
      default:
        // all other attributes are readonly since the event is a test clone
        return false;
    }
  }

  /**
   * We must assume that this method was called knowing in advance that the event is a 1-click test event.
   *
   * @param attribute
   * @param user
   */
  private canEditOneClickTestEvent(attribute: string, user: User) {
    switch (attribute) {
      case 'userSupportedChallenges':
        return user.isEventAdmin;
      case 'users':
      case 'teams':
      case 'preApprovedFacilitators':
      case 'importTeamProperties':
        // allow importing team challenge properties
        // allow adding new facilitators (testers)
        // always allow editing teams and users
        return true;
      default:
        // all other attributes are readonly since the event is a test clone
        return false;
    }
  }

  /**
   * We must assume that this method was called knowing in advance that the event is in a final state.
   *
   * @param attribute
   * @param user
   */
  private canEditFinalAttribute(attribute: string, user: User) {
    switch (attribute) {
      case 'userSupportedChallenges':
        return user.isEventAdmin;
      case 'users':
      case 'teams':
        // always allow editing teams and users
        return true;
      default:
        if (this.isClone || this.oneClickTestEvent) {
          return false;
        }
        // fall to the next switch for other attributes
        break;
    }

    // the event is in a final state. so only limited attributes can be modified
    switch (attribute) {
      case 'title':
      case 'description':
      case 'type':
      case 'audienceType':
      case 'notes':
      case 'labExtensionHours':
      case 'preApprovedFacilitators':
        return user.isEventAdmin;
      case 'owners':
        // always allow editing teams and users
        // always allow editing the owners, this allows sharing this page with other individuals
        return true;
      case 'eventPermissions':
        // always allow editing teams and users
        // always allow editing the event permissions, this allows sharing this page with other individuals
        return true;
      default:
        // all other attributes are readonly since the event is final
        return false;
    }
  }

  /**
   * Format an event URL
   *
   * @param baseUrl
   * @private
   */
  private formatEventUrl(baseUrl: string): string {
    return this.requiresSkillBuilderSubscription
      ? `${baseUrl}&${JamConstants.SKILL_BUILDER_QUERY_PARAM}=true`
      : baseUrl;
  }

  /**
   * Whether this event requires any skill builder subscription.
   */
  private get requiresSkillBuilderSubscription() {
    return this.validSkillBuilderSubscriptions.length > 0;
  }

  // milliseconds until event ends
  get timeRemainingMillis(): number {
    return moment.parseZone(this.endDate).valueOf() - Date.now();
  }

  get labStartTime(): string {
    if (this.isClone) {
      // test clones deploy labs from their start date with no offset
      return moment.parseZone(this.startDate).format();
    }
    return moment.parseZone(this.startDate).subtract(this.warmupOffsetHours, 'hours').format();
  }

  get testCloneStartTime(): string {
    return moment.parseZone(this.startDate).subtract(this.testingDays).format();
  }

  get labEndTime(): string {
    if (this.test || this.isClone) {
      // test events have no lab extension period
      return moment.parseZone(this.endDate).format();
    }
    return moment.parseZone(this.endDate).add(this.labExtensionHours, 'hours').format();
  }

  get uuid(): string {
    return this.name;
  }

  get wasEverApproved(): boolean {
    return this.cancellationTime !== null || this.approved;
  }

  get neverApproved(): boolean {
    return !this.wasEverApproved;
  }

  get ended(): boolean {
    return this.approved && this.status === EventStatus.ENDED;
  }

  getPendingChanges(user: User, eventConfig: EventConfiguration): DiffChange[] {
    if (!this.pendingChangeRequest || this.changeRequest === null) {
      return [];
    }

    return this.changeRequest.diffFromEvent(user, this, eventConfig);
  }

  get validFacilitatorsEmails(): boolean {
    return isEmailListValid(this.preApprovedFacilitators);
  }

  get validOwnerEmails(): boolean {
    return isEmailListValid(this.owners);
  }

  get numChallenges(): number {
    return this.challengeDescriptors.length;
  }

  validateEventRequest(addErrorFlashbar: AddErrorFlashbar) {
    if (!this.title) {
      addErrorFlashbar(i18nKeys.events.eventDetails.messages.errors.eventTitle);
      return false;
    }

    if (!this.startDate) {
      addErrorFlashbar(i18nKeys.events.eventDetails.messages.errors.eventStartDate);
      return false;
    }

    if (!this.endDate) {
      addErrorFlashbar(i18nKeys.events.eventDetails.messages.errors.eventDuration);
      return false;
    }

    if (!this.minExpectedParticipants || !this.maxExpectedParticipants) {
      addErrorFlashbar(i18nKeys.events.eventDetails.messages.errors.expectedParticipantCount);
      return false;
    }

    if (this.minExpectedParticipants > this.maxExpectedParticipants) {
      addErrorFlashbar(i18nKeys.events.eventDetails.messages.errors.minimumExpectedParticipants);
      return false;
    }

    if (!this.challengeDescriptors || this.challengeDescriptors.length < 1) {
      addErrorFlashbar(i18nKeys.events.eventDetails.messages.errors.selectChallenges);
      return false;
    }

    return true;
  }

  generateRandomKey() {
    const chars = '123456789ABCDEFGHJKLMNPQRSTUVWXTZabcdefghikmnpqrstuvwxyz';
    const length = 6;
    let output = '';
    while (output.length < length) {
      const index = Math.floor(Math.random() * chars.length);
      output += chars.substring(index, index + 1);
    }
    this.eventCode = output;
  }

  get shouldShowWarnings(): boolean {
    return !this.isAutoGenerated;
  }

  get isAutoGenerated(): boolean {
    return this.oneClickTestEvent || this.isCampaignEvent;
  }

  get testUrlWithMaskedSecretKey() {
    return getTestEventUrl(this);
    // return this.testCloneCode ? `${getTestEventUrl(this)}?code=******` : getTestEventUrl(this);
  }

  get testUrlWithSecretKey(): common.NullableString {
    return getTestEventUrl(this);
    // return this.testCloneCode ? `${getTestEventUrl(this)}?code=${this.testCloneCode}` : getTestEventUrl(this);
  }

  get url() {
    if (this.isCampaignEvent && this.id && this.campaignGroupId) {
      return getCampaignUrl(this.id, this.campaignGroupId);
    } else if (this.id) {
      return getEventUrl(this.id);
    }
  }

  get urlWithSecretKey(): common.NullableString {
    if (this.url) {
      // return this.eventCode ? `${this.url}?code=${this.eventCode}` : this.url;
      return this.url;
    } else {
      return null;
    }
  }

  get urlWithMaskedSecretKey(): common.NullableString {
    if (this.url) {
      // return this.eventCode ? `${this.url}?code=******` : this.url;
      return this.url;
    } else {
      return null;
    }
  }

  get urlBasePath() {
    return getEventUrl('');
  }

  get startingHour(): number | null {
    return EventTimeUtils.getHour(this.startDate, this.timezone);
  }

  get startsDuringBusinessHours(): boolean {
    const hour = this.startingHour;
    if (hour) {
      return hour >= 8 && hour <= 18;
    } else {
      return false;
    }
  }

  get startDateInEventLocalTime(): common.NullableString {
    return EventTimeUtils.getTimeInEventLocalTime(this.startDate, this.timezone, {
      includeDate: true,
      includeTime: false,
    });
  }

  get startDateInBrowserTime(): common.NullableString {
    return EventTimeUtils.getTimeInBrowserLocalTime(this.startDate, { includeDate: true, includeTime: false });
  }

  get startTimeInEventLocalTime(): common.NullableString {
    return EventTimeUtils.getTimeInEventLocalTime(this.startDate, this.timezone, {
      includeDate: false,
      includeTime: true,
    });
  }

  get startDateAndTimeInEventLocalTime(): common.NullableString {
    return EventTimeUtils.getTimeInEventLocalTime(this.startDate, this.timezone, {
      includeDate: true,
      includeTime: true,
    });
  }

  get autoUnlockChallengesLocalTime(): common.NullableString {
    return EventTimeUtils.addMinutesToEventLocalTime(this.startDate, this.timezone, this.autoUnlockChallengesOffsetMinutes);
  }

  get endTimeInEventLocalTime(): common.NullableString {
    return EventTimeUtils.getTimeInEventLocalTime(this.endDate, this.timezone, {
      includeDate: false,
      includeTime: true,
    });
  }

  get endDateInBrowserTime(): common.NullableString {
    return EventTimeUtils.getTimeInBrowserLocalTime(this.endDate, { includeDate: true, includeTime: false });
  }

  get startTimeInBrowserLocalTime(): common.NullableString {
    return EventTimeUtils.getTimeInBrowserLocalTime(this.startDate, { includeDate: false, includeTime: true });
  }

  get startTimeInUTCTime(): common.NullableString {
    return EventTimeUtils.getTimeInUTCTime(this.startDate);
  }

  get endTimeInUTCTime(): common.NullableString {
    return EventTimeUtils.getTimeInUTCTime(this.endDate);
  }

  get endDateInEventLocalTime(): common.NullableString {
    return EventTimeUtils.getTimeInEventLocalTime(this.endDate, null, { includeDate: true, includeTime: false });
  }

  get endTimeInBrowserLocalTime(): common.NullableString {
    return EventTimeUtils.getTimeInBrowserLocalTime(this.endDate, { includeDate: false, includeTime: true });
  }

  get utcOffset(): common.NullableString {
    return EventTimeUtils.getUTCOffset(this.startDate, this.timezone);
  }

  get inSameTimezoneAsBrowser(): boolean {
    return EventTimeUtils.isEventInSameTimezoneAsBrowser(this);
  }

  get isBeforeStart(): boolean {
    return !this.isAfterStart;
  }

  get isAfterStart(): boolean {
    if (this.startDate) {
      return moment().isAfter(moment.parseZone(this.startDate));
    }
    return false;
  }

  get isBeforeEnd(): boolean {
    return !this.isAfterEnd;
  }

  get isAfterEnd(): boolean {
    if (this.endDate) {
      return moment().isAfter(moment.parseZone(this.endDate));
    }
    return false;
  }

  generateWarnings(allChallengesById: Dictionary<ChallengeWrapper>): void {
    const warnings: { [warning: string]: boolean } = {};
    const challengeWarnings: { [warning: string]: string } = {};
    let numSSH = 0;

    if (this.shouldShowWarnings) {
      if (this.startDate && !this.startsDuringBusinessHours) {
        warnings.outsideBusinessHours = true;
      }

      // create a map of which warnings were found for which challenges,
      // so that we can merge the warnings
      const challengeWarningMap: { [warning: string]: Set<string> } = {};
      const challengeTitleMap: { [challengeId: string]: string } = {};

      this.challengeDescriptors.forEach((cd: ChallengeDescriptor) => {
        const wrapper: ChallengeWrapper = allChallengesById[cd.challengeId as string];
        if (wrapper && wrapper.latestApproved && wrapper.challengeId) {
          // find all warnings for this challenge and index them
          const allChallengeWarnings: ChallengeWarning = getChallengeWarnings(
            wrapper.latestApproved,
            wrapper.globalStatistics,
            this
          );
          Object.keys(allChallengeWarnings).forEach((warning: string) => {
            if (!!allChallengeWarnings[warning as keyof ChallengeWarning]) {
              const challengeIds = challengeWarningMap[warning] || new Set<string>();
              challengeIds.add(wrapper.challengeId as string);
              challengeWarningMap[warning] = challengeIds;
            }
          });

          // index the title
          challengeTitleMap[wrapper.challengeId] = wrapper.latestApproved.props.title || wrapper.challengeId;

          // track how many challenges require SSH
          if (wrapper.latestApproved.props.sshKeyPairRequired) {
            numSSH++;
          }
        }
      });

      Object.entries(challengeWarningMap).forEach(([warning, challengeIds]) => {
        const challengeTitles = Array.from(challengeIds).map((id) => challengeTitleMap[id]);
        challengeWarnings[warning] = challengeTitles.join(', ');
      });
    }

    const result: EventWarnings = new EventWarnings();
    result.allWarnings = { ...warnings, ...challengeWarnings };
    result.challengeWarnings = { ...challengeWarnings };
    result.numChallengesRequireSSH = numSSH;
    this.warnings = result;
  }
}

@jsonObject
export class GraphQLErrorResponse {
  message: string;
  extensions: {
    serviceName: string;
    code: string;
    stacktrace: string[];
  }
}

@jsonObject
export class GraphQLUserResponse {
  id: string;
  emailAddress: string;
  errors?: GraphQLErrorResponse[];
}

@jsonObject
export class GraphQLUserRequest {
  query: string;
  operationName: string;
}

@jsonObject
export class SessionRequest {
  noOfParticipants: number;
  startDate: string;
  timezone: string;
  catalogId: string;
}

@jsonObject
export class SessionResponse {
  id: string;
  url: string;

  static fromPlainObject(obj: object): SessionResponse {
    const res: SessionResponse = Object.assign(new SessionResponse(), obj || {});
    return res;
  }
}

@jsonObject
export class Event extends EventBase implements WithChallengesAndScoring, common.WithEventPermissions {
  @jsonArrayMember(Team)
  teams: Team[] = [];

  @jsonArrayMember(TeamMember)
  unassignedParticipants: TeamMember[] = [];

  // required for the challenge-selection component (WithChallengesAndScoring interface)
  @jsonMember(String)
  idAttribute = 'name';

  @jsonMember(common.NullableStringValue)
  orderId?: common.NullableString = null;

  // required for the challenge-selection component (WithChallengesAndScoring interface)
  getScoringSettings(): EventScoringSettings {
    return this.scoringSettings;
  }

  setScoringSettings(scoringSettings: EventScoringSettings) {
    this.scoringSettings = scoringSettings;
  }

  withDefaults(user: User, eventConfig: EventConfiguration): Event {
    return Event.setDefaults(user, this, eventConfig);
  }

  getOwners(): string[] {
    return getEmailsFromEventPermissions(this.eventPermissions || [], 'isOwner');
  }
  getFacilitators(): string[] {
    return getEmailsFromEventPermissions(this.eventPermissions || [], 'isFacilitator');
  }

  hasOwnerPermission(email: string): boolean {
    return this.getOwners().includes(email);
  }

  clone(): Event {
    return cloneDeep(this);
  }

  get showEventId(): boolean {
    if (this.cancelled) {
      return false;
    }
    if (this.denied) {
      return false;
    }
    if (this.isCampaignEvent) {
      return false;
    }

    return this.approved;
  }
}

@jsonObject
export class EventFeedback {
  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;

  @jsonMember(Number)
  eventRank = 0;

  @jsonMember(Number)
  speakerRank = 0;

  @jsonMember(common.NullableBooleanValue)
  didYouLearnSomethingNew: common.NullableBoolean = null;

  @jsonMember(common.NullableStringValue)
  notes: common.NullableString = null;

  @jsonMember(common.NullableDateStringValue)
  createdDate: common.NullableDateString = null;

  @jsonMember(common.NullableDateStringValue)
  updatedDate: common.NullableDateString = null;

  @jsonMember(common.NullableNumberValue)
  version: common.NullableNumber = null;
}

export interface EventChallengeFeedback {
  [challengeId: string]: ChallengeFeedback[];
}

export interface EventChallengeFeedbackSummaries {
  [challengeId: string]: ChallengeFeedbackSummary;
}

@jsonObject
export class EventFeedbackSummary {
  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;

  @jsonMember(Number)
  total = 0;

  @jsonMember(Number)
  totalWithComments = 0;

  @jsonMember(Number)
  rating1 = 0;

  @jsonMember(Number)
  rating2 = 0;

  @jsonMember(Number)
  rating3 = 0;

  @jsonMember(Number)
  rating4 = 0;

  @jsonMember(Number)
  rating5 = 0;

  @jsonMember(Number)
  speaker1 = 0;

  @jsonMember(Number)
  speaker2 = 0;

  @jsonMember(Number)
  speaker3 = 0;

  @jsonMember(Number)
  speaker4 = 0;

  @jsonMember(Number)
  speaker5 = 0;

  @jsonMember(Number)
  learnedSomethingNew = 0;

  @jsonMember(Number)
  didNotLearnSomethingNew = 0;
}

@jsonObject
export class TeamChallengeProperties {
  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  teamName: common.NullableString = null;

  @jsonArrayMember(Object)
  properties: { key: string; value: string }[] = [];
}

@jsonObject
export class EventConfiguration {
  @jsonMember(Number)
  durationLimitHours = 8;

  @jsonMember(Number)
  numChallengesLimit = 14;

  @jsonMember(Boolean)
  allowEventReset = false;

  @jsonMember(Boolean)
  allowEventTeamsReset = false;

  /**
   * Default video to show on the event onboarding page.
   * Can be overridden per event.
   */

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  @jsonMember(common.NullableClassValue(OnboardingVideoDetails))
  defaultOnboardingVideo: common.Nullable<OnboardingVideoDetails> = null;

  /**
   * Default number of minutes before an events starts when participants can begin to form teams.
   * Can be overridden per event.
   */
  @jsonMember(common.NullableNumberValue)
  defaultFormTeamsMinsBeforeEventStart: common.NullableNumber = null;

  /**
   * Default team assignment type.
   * Can be overridden per event.
   */
  @jsonMember(common.NullableStringValue)
  defaultTeamAssignmentType: common.Nullable<TeamAssignmentType> = null;

  /**
   * Set of all origins that an event onboarding video can be located in.
   */
  @jsonArrayMember(String)
  onboardingVideoAllowedOrigins: string[] = [];

  static fromPlainObject(obj: object): EventConfiguration {
    const config: EventConfiguration = Object.assign(new EventConfiguration(), obj || {});
    if (config.defaultOnboardingVideo) {
      config.defaultOnboardingVideo = fromPlainObject(
        config.defaultOnboardingVideo,
        OnboardingVideoDetails
      ) as OnboardingVideoDetails;
    }
    config.onboardingVideoAllowedOrigins = [...(config.onboardingVideoAllowedOrigins || [])];
    return config;
  }
}

/**
 * Get the status to be displayed on the event list page for an event.
 *
 * @param event
 */
// This below code is deprecated as we will use only status event.field return by the backend for 6 possible values Schedule/pending/ongoing/ended/cancelled/changeRequested
export const getEventListStatus = (
  event?: Event
): EventStatus | common.ChangeRequestPendingStatus | common.RequestUnapprovedStatus => {
  if (!event) {
    return EventStatus.NOT_STARTED;
  }
  if (event.cancellationTime) {
    return EventStatus.CANCELLED;
  }
  if (event.approved) {
    if (event.changeRequest && event.changeRequest.pending) {
      // it is ok to cast this to a pending status since we are checking above whether the change request is pending.
      return event.changeRequest.status as unknown as common.ChangeRequestPendingStatus;
    }

  }
  // it is ok to cast this to an unapproved status since we are checking above for approval.
  return event.approvalStatus as unknown as common.RequestUnapprovedStatus;
};

export interface LabStatuses {
  [live: string]: {
    start: number;
    end: number;
  };
}

// eslint-disable-next-line no-shadow
export enum EventParticipantsFields {
  PARTICIPANT = 'participant',
}

export type EventValidationFields = EventParticipantsFields;
