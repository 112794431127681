import React from 'react';
import { useTranslation } from 'react-i18next';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import { i18nKeys } from '@/src/utils/i18n.utils';
import {
  Box,
  StatusIndicator,
  Popover,
  Button,
  ColumnLayout,
  ExpandableSection,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import './JamChallengeProperties.scss';

interface ValueWithLabelProps {
  label: string;
  value: string;
}

const ValueWithLabel: React.FC<ValueWithLabelProps> = ({ label, value }) => (
  <SpaceBetween direction="horizontal" size="s">
    <Box variant="awsui-key-label">{label}</Box>
    <div style={{ display: 'flex' }}>
      <span className="challenge-output-properties-value">{value}</span>
      <Popover
        size="small"
        position="top"
        triggerType="custom"
        dismissButton={false}
        content={
          <div style={{ display: 'flex' }}>
            <StatusIndicator type="success" />
            <span className="challenge-output-properties-value">{value}</span>
          </div>
        }>
        <Button
          variant="inline-icon"
          iconName="copy"
          ariaLabel={value}
          onClick={() => void navigator.clipboard.writeText(value)}
        />
      </Popover>
    </div>
  </SpaceBetween>
);

interface JamChallengePropertiesProps {
  defaultExpanded?: boolean;
}

export const JamChallengeProperties: React.FC<JamChallengePropertiesProps> = ({ defaultExpanded = false }) => {
  const { t } = useTranslation();
  const { outputProperties, challengeProgress } = useJamChallengeDetails();
  if (!outputProperties || outputProperties.length === 0) {
    return null;
  }

  if (!challengeProgress?.started || challengeProgress?.completed) return null;

  return (
    <div className="challenge-output-properties-container">
      <ExpandableSection
        defaultExpanded={defaultExpanded}
        variant="container"
        headerText={t(i18nKeys.myJams.challenges.details.outputProperties.title)}>
        <ColumnLayout columns={2} variant="text-grid">
          {outputProperties.map((property, index) => (
            <ValueWithLabel key={index} label={property.label} value={property.value} />
          ))}
        </ColumnLayout>
      </ExpandableSection>
    </div>
  );
};
