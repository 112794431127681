import { PropertyFilterToken, useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  Pagination,
  Table,
  TableProps,
  PropertyFilter,
  Toggle,
  SpaceBetween,
  Popover,
  Link,
  Icon,
  CollectionPreferences,
} from '@amzn/awsui-components-react';
import { STABILITY_HEALTH_CRITERIA } from '@/src/constants/event-template.constants';
import { PropertyFilterProperty } from '@cloudscape-design/collection-hooks';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../store/challenge.context';
import { useSplitPanel } from '../../../store/split-panel.context';
import { ChallengeDescriptor, ChallengeListItem, ChallengeStatus, ChallengeUtils } from '../../../types/Challenge';
import { i18nKeys } from '../../../utils/i18n.utils';
import { TableEmptyState } from '../TableEmptyState';
import { TableHeader } from '../TableHeader';
import { COLUMN_DEFINITIONS, filteringFunction } from './browse-challenges-list-config';
import BrowseChallengesSplitPanel from './BrowseChallengesSplitPanel';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react/uxdg';
import { UserPreferenceKeys, useUser } from '@/src/store/user.context';

interface BrowseChallengesProps {
  handleChallengeAction?: (payload: ChallengeDescriptor[]) => void;
  toggleChallengeInfo: (challengeListItem: ChallengeListItem) => void;
  currentChallengeDescriptors: ChallengeDescriptor[];
  editMode?: boolean;
  showHideUnstableToggle?: boolean;
  displayChallenges?: string[];
  showSelectedSplitPanel?: boolean;
  actionButtons?: ReactNode;
  excludedChallenges?: ChallengeDescriptor[];
  defaultQueryTokens?: PropertyFilterToken[]
}

const BrowseChallenges: React.FC<BrowseChallengesProps> = ({
  handleChallengeAction,
  currentChallengeDescriptors,
  toggleChallengeInfo,
  editMode,
  displayChallenges = [],
  showSelectedSplitPanel = true,
  showHideUnstableToggle = false, // by default toggle will not be visible if you not passed from parents
  actionButtons,
  excludedChallenges = [],
  defaultQueryTokens = [],
}) => {
  const { user } = useUser();
  const {
    challengeListItems,
    loadingChallenges,
    challengeWrapperMap,
    challengeWrappers,
    getChallengeDescriptorsToCopy,
    getChallenges,
  } = useChallenges();
  const [selectedChallenges, setSelectedChallenges] = useState<ChallengeListItem[]>([]);
  const { renderSplitPanelContent, toggleShowSplitPanel } = useSplitPanel();
  const [toggleUnstableChallenges, setToggleUnstableChallenges] = useState(true);
  const { t } = useTranslation();

  const showToggleButton = (
    <SpaceBetween direction="horizontal" size="xs" alignItems="center">
      <Toggle checked={toggleUnstableChallenges} onChange={({ detail }) => setToggleUnstableChallenges(detail.checked)} data-testid="unstableChallengeToggle">
        {t(i18nKeys.eventTemplates.table.header.actionToggleChallenges)}
      </Toggle>
      <Popover content={t(i18nKeys.eventTemplates.table.header.popoverContent)} triggerType="custom">
        <Link ariaLabel="info">
          <Icon name="status-info" alt="info" />
        </Link>
      </Popover>
      {actionButtons}
    </SpaceBetween>
  );

  useEffect(() => {
    if (!showSelectedSplitPanel) return;
    if (selectedChallenges.length === 0) {
      toggleShowSplitPanel(false);
      return;
    }

    toggleShowSplitPanel(true);
    renderSplitPanelContent(
      t(i18nKeys.challenges.browseChallenges.selectedChallenges, {
        selectedChallengeCount: selectedChallenges.length,
      }),
      <BrowseChallengesSplitPanel selectedChallenges={selectedChallenges || []} />
    );
  }, [selectedChallenges]);

  useEffect(() => {
    if (!challengeWrappers) {
      void getChallenges(false, false, true);
    }
  }, [challengeWrappers]);

  useEffect(() => {
    populateSelectedChallengesFromCurrentDescriptors();
  }, [currentChallengeDescriptors]);

  const lastUsedColumnIndex = COLUMN_DEFINITIONS(toggleChallengeInfo, editMode).findIndex(
    (columnDef: TableProps.ColumnDefinition<ChallengeListItem>) => columnDef.id === 'last-used'
  );

  const filterChallenges = (challengeList: ChallengeListItem[] | undefined) => {
    let newFilteredChallenges = challengeList || [];

    if (displayChallenges.length > 0 && !editMode) {
      newFilteredChallenges = newFilteredChallenges.filter((challenge) =>
        displayChallenges.includes(challenge.challengeId || '')
      );
    }
    
    if (editMode) {
      newFilteredChallenges = newFilteredChallenges
        .filter((challenge) => challenge.status === ChallengeStatus.APPROVED)
        .filter((challenge) => {
          if (user?.isSuperAdmin || user?.isEventAdmin) return true; // don't filter for admins
          return challenge.challengeId && !(challengeWrapperMap[challenge.challengeId] || challenge).isPrivate;
        })
        .filter(
          (challenge) =>
            challenge.challengeId &&
            challengeWrapperMap[challenge.challengeId] &&
            (challengeWrapperMap[challenge.challengeId] || challenge).isEligibleForEvents()
        );
    }

    if (excludedChallenges.length > 0) {
      newFilteredChallenges = newFilteredChallenges.filter((challenge) => {
        const foundChallengeIndex = excludedChallenges.findIndex(
          (excludedChallenge: ChallengeDescriptor) => excludedChallenge.challengeId === challenge.challengeId
        );
        return foundChallengeIndex < 0;
      });
    }
    if (toggleUnstableChallenges && showHideUnstableToggle) {
      newFilteredChallenges = newFilteredChallenges.filter(
        (challengeListItem) =>
          challengeListItem.stability !== null &&
          challengeListItem.stability > STABILITY_HEALTH_CRITERIA &&
          !challengeListItem.challengeId?.includes('test')
      );
    }

    newFilteredChallenges = newFilteredChallenges.filter((c) => !ChallengeUtils.isIndividualLearningType(c));
    return newFilteredChallenges;
  };

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 10,
    visibleContent: ['title', 'type'],
  });

  const filteringProperties: PropertyFilterProperty[] = [
    {
      key: 'title',
      operators: ['=', ':'],
      propertyLabel: 'Title',
      groupValuesLabel: 'title',
    },
    {
      key: 'status',
      operators: ['=', ':'],
      propertyLabel: 'Status',
      groupValuesLabel: 'status',
    },
    {
      key: 'owner',
      operators: ['=', ':'],
      propertyLabel: 'Owner',
      groupValuesLabel: 'owner',
    },
    {
      key: 'category',
      operators: ['=', ':'],
      propertyLabel: 'Category',
      groupValuesLabel: 'category',
    },
    {
      key: 'difficulty',
      operators: ['=', ':'],
      propertyLabel: 'Difficulty',
      groupValuesLabel: 'difficulty',
    },
    {
      key: 'awsServices',
      operators: ['=', ':'],
      propertyLabel: 'AWS Service',
      groupValuesLabel: 'awsServices',
    },
    {
      key: 'tags',
      operators: ['=', ':'],
      propertyLabel: 'Tag',
      groupValuesLabel: 'tags',
    },
  ];

  const initialChallengeList = filterChallenges((challengeListItems));
  const {
    items,
    filteredItemsCount,
    actions,
    collectionProps,
    paginationProps,
    propertyFilterProps
  } = useCollection(initialChallengeList, {
      filtering: {
        filteringFunction: filteringFunction(t),
        empty: (
          <TableEmptyState
            title={t(i18nKeys.challenges.list.empty.title)}
            subtitle={t(i18nKeys.challenges.list.empty.subtitle)}
          />
        ),
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      propertyFiltering: {
        filteringProperties,
        defaultQuery: {
          tokens: defaultQueryTokens,
          operation: "and"
        }
      },
      sorting: {
        defaultState: {
          sortingColumn: COLUMN_DEFINITIONS(toggleChallengeInfo)[lastUsedColumnIndex],
          isDescending: false,
        },
      },
    });

  const handleChallengeSelection = (selectedChallengeItems: ChallengeListItem[]) => {
    setSelectedChallenges(selectedChallengeItems);
    if (handleChallengeAction) {
      handleChallengeAction(getChallengeDescriptorsToCopy(currentChallengeDescriptors, selectedChallengeItems));
    }
    if (showSelectedSplitPanel) {
      renderSplitPanelContent(
        t(i18nKeys.challenges.browseChallenges.selectedChallenges, {
          selectedChallengeCount: selectedChallengeItems.length,
        }),
        <BrowseChallengesSplitPanel selectedChallenges={selectedChallengeItems} />
      );
    }
  };

  const populateSelectedChallengesFromCurrentDescriptors = () => {
    const newSelectedChallenges: ChallengeListItem[] = [];
    currentChallengeDescriptors.map((challenge) => {
      if (challenge.challengeId) {
        const challengeIndex = challengeListItems?.findIndex((item) => item.challengeId === challenge.challengeId);
        if (challengeIndex && challengeListItems) {
          newSelectedChallenges.push(challengeListItems[challengeIndex]);
        }
      }
    });
    setSelectedChallenges(newSelectedChallenges);
  };

  const handleSetPreferences = (tablePreferences: CollectionPreferencesProps.Preferences) => {
    if (tablePreferences.pageSize) {
      sessionStorage.setItem(UserPreferenceKeys.PAGE_SIZE, tablePreferences.pageSize.toString());
    }
    if (tablePreferences.custom) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      sessionStorage.setItem(UserPreferenceKeys.DISPLAY_TIME, tablePreferences.custom);
    }
    setPreferences(tablePreferences);
  };

  const BrowseChallengesHeader = () => {
    const editModeProps = {
      selectedItems: selectedChallenges,
      showZero: true 
    };
    return <TableHeader
      {...(editMode ? editModeProps : {})}
      totalItems={filteredItemsCount}
      title={t(i18nKeys.challenges.title)}
      actionButtons={showHideUnstableToggle ? showToggleButton : actionButtons}
    />
  };
  const editModeOnSelectionChange = ({ detail }: { detail: TableProps.SelectionChangeDetail<ChallengeListItem>}) => handleChallengeSelection(detail.selectedItems);
  return (
    <React.Fragment>
        <Table
          {...collectionProps}
          {...(editMode ? { onSelectionChange: editModeOnSelectionChange, selectedItems: selectedChallenges, selectionType: 'multi' }: {})}
          header={<BrowseChallengesHeader />}
          items={items || []}
          data-testid="challengesTable"
          columnDefinitions={COLUMN_DEFINITIONS(toggleChallengeInfo)}
          pagination={
            <Pagination
              ariaLabels={{
                nextPageLabel: t(i18nKeys.challenges.nextPageLabel),
                paginationLabel: t(i18nKeys.challenges.paginationLabel),
                previousPageLabel: t(i18nKeys.challenges.previousPageLabel),
                pageLabel: (pageNumber) => `page ${pageNumber}`,
              }}
              {...paginationProps}
            />
          }
          ariaLabels={{
            selectionGroupLabel: t(i18nKeys.challenges.selectionGroupLabel),
            allItemsSelectionLabel: ({ selectedItems }) =>
              `${selectedItems.length} ${selectedItems.length === 1 ? 'item' : 'items'} selected`,
            itemSelectionLabel: ({}, item: ChallengeListItem) => item.challengeId || '',
          }}
          loading={loadingChallenges}
          resizableColumns
          loadingText={t(i18nKeys.challenges.loadingChallenges)}
          preferences={
            <CollectionPreferences
              title={t(i18nKeys.events.fields.preferences.title)}
              onConfirm={({ detail }) => handleSetPreferences(detail)}
              confirmLabel={t(i18nKeys.general.confirm)}
              cancelLabel={t(i18nKeys.general.cancel)}
              preferences={preferences}
              pageSizePreference={{
                title: t(i18nKeys.events.fields.preferences.pageSize.title),
                options: [
                  { value: 10, label: t(i18nKeys.challenges.fields.preferences.pageSize.label, { count: 10 }) },
                  { value: 20, label: t(i18nKeys.challenges.fields.preferences.pageSize.label, { count: 20 }) },
                  { value: 30, label: t(i18nKeys.challenges.fields.preferences.pageSize.label, { count: 30 }) },
                  { value: 50, label: t(i18nKeys.challenges.fields.preferences.pageSize.label, { count: 50 }) },
                  { value: 100, label: t(i18nKeys.challenges.fields.preferences.pageSize.label, { count: 100 }) },
                ],
              }}
            />
          }
          filter={
            <Box margin={{ top: 'xs', bottom: 'xs' }}>
              <PropertyFilter
                {...propertyFilterProps}
                i18nStrings={{
                  filteringAriaLabel: t(i18nKeys.eventTemplates.customTable.filter.filteringAriaLabel),
                  dismissAriaLabel: t(i18nKeys.eventTemplates.customTable.filter.dismissAriaLabel),
                  filteringPlaceholder: t(i18nKeys.eventTemplates.table.challangePlaceholder),
                  groupValuesText: t(i18nKeys.eventTemplates.customTable.filter.groupValuesText),
                  groupPropertiesText: t(i18nKeys.eventTemplates.customTable.filter.groupPropertiesText),
                  operatorsText: t(i18nKeys.eventTemplates.customTable.filter.operatorsText),
                  operationAndText: t(i18nKeys.eventTemplates.customTable.filter.operationAndText),
                  operationOrText: t(i18nKeys.eventTemplates.customTable.filter.operationOrText),
                  operatorLessText: t(i18nKeys.eventTemplates.customTable.filter.operatorLessText),
                  operatorLessOrEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorLessOrEqualText),
                  operatorGreaterText: t(i18nKeys.eventTemplates.customTable.filter.operatorGreaterText),
                  operatorGreaterOrEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorGreaterOrEqualText),
                  operatorContainsText: t(i18nKeys.eventTemplates.customTable.filter.operatorContainsText),
                  operatorDoesNotContainText: t(i18nKeys.eventTemplates.customTable.filter.operatorDoesNotContainText),
                  operatorEqualsText: t(i18nKeys.eventTemplates.customTable.filter.operatorEqualsText),
                  operatorDoesNotEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorDoesNotEqualText),
                  editTokenHeader: t(i18nKeys.eventTemplates.customTable.filter.editTokenHeader),
                  propertyText: t(i18nKeys.eventTemplates.customTable.filter.propertyText),
                  operatorText: t(i18nKeys.eventTemplates.customTable.filter.operatorText),
                  valueText: t(i18nKeys.eventTemplates.customTable.filter.valueText),
                  cancelActionText: t(i18nKeys.eventTemplates.customTable.filter.cancelActionText),
                  applyActionText: t(i18nKeys.eventTemplates.customTable.filter.applyActionText),
                  allPropertiesLabel: t(i18nKeys.eventTemplates.customTable.filter.allPropertiesLabel),
                  tokenLimitShowMore: t(i18nKeys.eventTemplates.customTable.filter.tokenLimitShowMore),
                  tokenLimitShowFewer: t(i18nKeys.eventTemplates.customTable.filter.tokenLimitShowFewer),
                  clearFiltersText: t(i18nKeys.eventTemplates.customTable.filter.clearFiltersText),
                }}
              />
            </Box>
          }
        />
    </React.Fragment>
  );
};

export default BrowseChallenges;
