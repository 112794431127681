import React from 'react';
import ReactMarkdown from 'react-markdown';
import { PluggableList } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { decodeForMarkdown } from '../../utils/string.utils';

interface MarkdownProps {
  content?: string;
  removePageBreaks?: boolean;
  sanitize?: boolean;
  className?: string;
}

export const Markdown: React.FC<MarkdownProps> = ({
  content,
  removePageBreaks = false,
  sanitize = false,
  className,
}) => {
  const withoutPageBreakSymbols = (text: string): string => {
    return (text || '').replace(/\$PAGE_BREAK/g, '');
  };

  const decodedContent = () => {
    let result: string = content || '';
    if (result) {
      if (removePageBreaks) {
        result = withoutPageBreakSymbols(result);
      }
      result = decodeForMarkdown(result);
    }
    return result;
  };

  const rehypePlugins: PluggableList = [rehypeRaw];
  if (sanitize) {
    rehypePlugins.push(rehypeSanitize);
  }

  return (
    <ReactMarkdown className={className} remarkPlugins={[remarkGfm]} rehypePlugins={rehypePlugins}>
      {decodedContent()}
    </ReactMarkdown>
  );
};
